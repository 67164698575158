import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle, faCheckSquare, faCoffee, faEdit, faS } from '@fortawesome/fontawesome-free-solid';
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';
import img from '../../../img/helperCardImages/Get Support Image.png';

// VENUE INFORMATION IS IN venueData
// example venueData.v_name is the venue name

const CardGetSupport = ({dispatch, venueData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStatus,setActiveStatus] = useState("Not Available");
  const history = useHistory();

  useEffect(()=>{
    // You can add any side effects or logic here
  },[venueData]);

  const handleEdit = () => {
    history.push(`/edit/${venueData.v_id}`);
  }

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget  p-3">
          <div className="card__title">
            <h5 className="bold-text">Get Support</h5>
          </div>
          <div className="card__body">
            <Row>
              <Col xs={3}>
                <img src={img} alt="Edit Venue" />
              </Col>
              <Col xs={9}>
                <p>
                  We are here to assist you with any questions or concerns you may have.
                </p>
                <table className="table table-sm">
                  <tr>
                    <td className="text-left">Call us:</td>
                    <td className="text-right"><a href="tel:716-309-5193">716-309-5193</a></td>
                  </tr>
                  <tr>
                    <td className="text-left">Email us:</td>
                    <td className="text-right"><a href="mailto:support@bookwny.com">support@bookwny.com</a></td>
                  </tr>
                </table>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default connect(state => ({
  venueData: state.venue.venueData
}))(CardGetSupport);
import React, {useEffect, useState} from 'react';
import {
  Card, CardBody, Col, Button, Modal, Row, Alert, CustomInput
} from 'reactstrap';
import {connect} from "react-redux";
import ModelUser from "../../../../Models/User";
import ModelVenue from "../../../../Models/Venue";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle, faBolt, faSpinner, faDotCircle, faRocket} from '@fortawesome/fontawesome-free-solid';

import Http from "../../../../containers/App/Http";

const paperPlane = `${process.env.PUBLIC_URL}/img/pricing_cards/004-paper-plane.svg`;

const CardBasic = ({ dispatch, view, user, venueData, rtl, theme }) => {

  const [subscribeTo,setSubscribeTo] = useState(false);
  const [isCurrentPlan,setIsCurrentPlan] = useState(false);
  const [errorMsg,setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successMsg,setSuccess] = useState(false);
  const [processing,setProcessing] = useState(false);
  const [tierInfo,setTierInfo] = useState({
    name:'Free',
    productId:'',
    price:'$ 0.00',
    frequency:'Monthly',
    vti_advanced_id:'FREE'
  });
  const um = new ModelUser();
  const mu = new ModelVenue();

  useEffect(() => {
    if (venueData?.vti_advanced_id === "FREE")
      setIsCurrentPlan(true);
    else
      setIsCurrentPlan(false);
  }, [venueData]);

  const subscribeToThisTier = () => {
    setSubscribeTo(true);
  };

  const toggle = async () => {
    await setSubscribeTo(prevState => !prevState);
  };

  const handleTierChange=async ()=>{
    setProcessing(true);

    let subData = new FormData();
    subData.append('tierId',tierInfo.vti_advanced_id);
    subData.append('venueId',venueData.v_id);

    Http.post("/v/"+venueData.v_id+"/changeVenueTier",subData).then(async response => {
      if (response.data.status === 'failed') {
        setError(response.data.msg);
        setProcessing(false);
        return false;
      }

      // RELOAD THE USER OBJECT INTO REDUX
      await um.getUserInfo();
      await mu.getVenueData(venueData.v_id);

      setProcessing(false);
      setError(false);
      setSuccess(response.data.msg);
    });

  };

  return (
    <>
      <Col md={6} xl={4} sm={12}>
        <Card>
          <CardBody className="pricing-card pricing-card--primary">
            <div className="pricing-card__body">
              <img className="pricing-card__img" src={paperPlane} alt="" />
              <h3 className="pricing-card__plan">Basic</h3>
              <hr />
              <p className="pricing-card__price">$0<span>/mo</span></p>
              <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> 1 Lead per month</p>
              <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Email Support</p>
              <p className="pricing-card__feature text-left"><span className='color-bw-danger'><FontAwesomeIcon icon={faTimesCircle} size="lg"/></span> Phone Support</p>
              <p className="pricing-card__feature text-left"><span className='color-bw-danger'><FontAwesomeIcon icon={faTimesCircle} size="lg"/></span> Lead Shared With Others</p>
              
              {!isCurrentPlan && (
                <Button className="btn-block mt-3 btn-sm" color="primary" onClick={subscribeToThisTier}>
                  Select Plan
                </Button>
              )}
              {isCurrentPlan && (
                <Button className="btn-block mt-3 btn-sm" color="success">
                  Current Plan <FontAwesomeIcon icon={faDotCircle} />
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>

      {subscribeTo &&
      <Modal isOpen={subscribeTo} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="md">
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">Confirmation</h4>
        </div>

        <div className="modal__body pt-0">
          {successMsg !== false &&
          <Row>
            <Col xs={12} className="text-left">
              <h4>Venue Tier Changed</h4>
              <p>{successMsg}</p>
              <Button color={"danger"} className={"btn-sm mb-0 mt-2 btn-block"} onClick={toggle}>Close</Button>
            </Col>
          </Row>
          }
          {!successMsg &&

          <Col xs={'12'}>
            <Alert color="danger" isOpen={!!errorMsg}>
              {errorMsg}
            </Alert>
            <Alert color="success" isOpen={!!successMsg}>
              {successMsg}
            </Alert>
            <div className="form__form-group mt-3">
              <Col xs={'12'} className={'mt-3 mb-3'}>
                <Alert color="info" className="p-3">
                  <p><span className="bold-text">Information: </span>
                    By confirming the action we will charge your payment method on file and upgrade your account.
                    If you have any questions please reach out to our support team. Thank you!!!
                  </p>
                </Alert>
                <h4>Tier: {tierInfo.name}</h4>
                <h4>Price: {tierInfo.price}</h4>
                <h4>Frequency: {tierInfo.frequency}</h4>
              </Col>
              <button
                className="btn btn-primary btn-sm btn-block"
                type="button"
                onClick={handleTierChange}
                disabled={errorMsg || processing}
              >
                {processing ? (
                  <span>
        <FontAwesomeIcon icon={faSpinner} spin /> Loading...
      </span>
                ) : (
                  'Subscribe'
                )}
              </button>
            </div>
          </Col>

          }
        </div>

      </Modal>
      }
    </>
  );
}

export default connect(state => ({
  rtl: state.rtl,
  user : state.user.main,
  roles : state.user.roles,
  theme: state.theme,
  users:state.users.users,
  venueData:state.venue.venueData,
  venueMembers:state.venue.members
}))(CardBasic);

import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import ReportSelector from "./components/ReportSelector";
import {connect} from "react-redux";


const Reporting = ({ dispatch }) => {
    const { reportId } = useParams();

    useEffect(() => {
        console.log(reportId);
    },[]);

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Reporting Engine</h3>
                </Col>
            </Row>
            <Row>
                <ReportSelector/>
            </Row>
        </Container>
    );
};

export default connect(state => ({
    selectedReport:state.reporting.selectedReport,
}))(Reporting);
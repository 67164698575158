import React, { useEffect, useState } from 'react';
import { CustomInput } from 'reactstrap';
import  {Row, Card, CardBody, Col, Button, Alert, Modal} from 'reactstrap';
import SubscribeVenueToTier from './SubcribeVenueToTier';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CardElement} from "@stripe/react-stripe-js";
import Http from "../../../../containers/App/Http";
import {connect} from "react-redux";
import ModelUser from "../../../../Models/User";
import ModelVenue from "../../../../Models/Venue";
import AddPaymentMethod from '../../AddPaymentMethod';
import { faTimesCircle, faCheckCircle, faCheckSquare, faCoffee, faEdit, faRocket,faBolt,faSpinner } from '@fortawesome/fontawesome-free-solid';

const rocket = `${process.env.PUBLIC_URL}/img/pricing_cards/001-rocket.svg`;

const CardPremium = ({ dispatch, view, user, venueData, rtl, theme }) => {
  const [isYearly, setIsYearly] = useState(false);
  
  // Set initial tier information
  const [tierInfo, setTierInfo] = useState({
    name: 'Premium',
    productId: '',
    price: '$199.99',
    frequency: 'Monthly',
    vti_advanced_id: 'PREM',
  });

  const [subscribeTo, setSubscribeTo] = useState(false);
  const [isCurrentPlan, setIsCurrentPlan] = useState(false);
  const [errorMsg, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successMsg, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const um = new ModelUser();
  const mu = new ModelVenue();

  // Determine if the current plan is yearly or monthly based on the subscription data
  useEffect(() => {
    const currentSubscription = venueData?.subscription?.find(sub => sub.s_status === 'active');

    if (currentSubscription) {
      const interval = currentSubscription.s_interval;
      setIsYearly(interval === 'y');
      console.log('isYearly after setting:', interval === 'y'); // Log the value of isYearly
      console.log('isYearly after setting:'+currentSubscription.s_interval); // Log the value of isYearly
    
      setTierInfo(prevInfo => ({
        ...prevInfo,
        price: interval === 'y' ? '$1999.99' : '$199.99',
        frequency: interval === 'y' ? 'Yearly' : 'Monthly',
      }));
    }

    if (venueData?.vti_advanced_id === "PREM") {
      setIsCurrentPlan(true);
    } else {
      setIsCurrentPlan(false);
    }
  }, [venueData]);

  const togglePlan = () => {
    setIsYearly(!isYearly);
    setTierInfo(prevInfo => ({
      ...prevInfo,
      price: !isYearly ? '$1999.99' : '$199.99',
      frequency: !isYearly ? 'Yearly' : 'Monthly',
    }));
  };

  const subscribeToThisTier = () => {
    if (!user.hasPaymentMethod) {
      toggleAddPaymentMethodModal();
    } else {
      setSubscribeTo(true);
    }
  };

  const toggle = async () => {
    await setSubscribeTo(prevState => !prevState);
  };

  const handleTierChange = async () => {
    setProcessing(true);

    let subData = new FormData();
    subData.append('tierId', tierInfo.vti_advanced_id);
    subData.append('venueId', venueData.v_id);
    subData.append('frequency', isYearly ? 'y' : 'm');

    Http.post("/v/" + venueData.v_id + "/changeVenueTier", subData).then(async (response) => {
      if (response.data.status === 'failed') {
        setError(response.data.msg);
        setProcessing(false);
        return false;
      }

      // RELOAD THE USER OBJECT INTO REDUX
      await um.getUserInfo();
      await mu.getVenueData(venueData.v_id);

      setProcessing(false);
      setError(false);
      setSuccess(response.data.msg);
    });
  };

  const toggleAddPaymentMethodModal = () => {
    setOpenModal(prevState => !prevState);
  };

  const paymentMethodSubmitted = () => {
    setOpenModal(prevState => !prevState);
    // IF SUCCESS THEN OPEN THE NEXT MODAL WHICH IS TO SELECT THE PLAN
    setSubscribeTo(prevState => !prevState);
  };

  return (
    <>
      <Col md={6} xl={4} sm={12}>
      <Card>
  <CardBody className="pricing-card pricing-card--danger">
    <div className="pricing-card__body">
      <CustomInput
        type="switch"
        id="planToggle1"
        name="planToggle1"
        label={<span>Monthly/Yearly</span>}
        checked={isYearly} // Use 'checked' for switch type inputs
        onChange={togglePlan} // Toggle the plan on change
      />

      <img className="pricing-card__img" src={rocket} alt="" />
      <h3 className="pricing-card__plan">{isYearly ? 'Yearly Premium' : 'Premium'}</h3>
      <hr />
      {isYearly ? (
        <>
          
            <p className="pricing-card__price">$1999.99<span>/yr</span></p>
          
          
            <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> DISCOUNT for yearly price</p>
            <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Higher Search Placement</p>
            <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Unlimited Leads</p>
            <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> No Other Venue Recommendation</p>
            <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Phone Support</p>
          
          
        </>
      ) : (
        <>
          <p className="pricing-card__price">$199.99<span>/mo</span></p>
          <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Higher Search Placement</p>
          <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Unlimited Leads</p>
          <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> No Other Venue Recommendation</p>
          <p className="pricing-card__feature text-left"><span className='color-bw-secondary'><FontAwesomeIcon icon={faCheckCircle} size="lg"/></span> Phone Support</p>
        </>
      )}

      {!isCurrentPlan && (
        <Button className="btn-block mt-3 btn-sm" color="primary" onClick={subscribeToThisTier}>Select Plan</Button>
      )}
      {isCurrentPlan && (
        <Button className="btn-block mt-3 btn-sm" color="success">Current Plan <FontAwesomeIcon icon={faBolt} /></Button>
      )}
    </div>
  </CardBody>
</Card>

      </Col>
      <AddPaymentMethod toggleModal={openModal} toggleCallback={toggleAddPaymentMethodModal} callback={paymentMethodSubmitted} />

      {subscribeTo && (
        <Modal isOpen={subscribeTo} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="md">
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              aria-label="modal__close-btn"
              type="button"
              onClick={toggle}
            />
            <h4 className="text-modal modal__title">Confirm</h4>
          </div>

          <div className="modal__body pt-0">
            {successMsg !== false && (
              <Row>
                <Col xs={12} className="text-left">
                  <h4>Venue Tier Changed</h4>
                  <p>{successMsg}</p>
                  <Button color="danger" className="btn-sm mb-0 mt-2 btn-block" onClick={toggle}>Close</Button>
                </Col>
              </Row>
            )}
            {!successMsg && (
              <Col xs="12">
                <Alert color="danger" isOpen={!!errorMsg}>
                  {errorMsg}
                </Alert>
                <Alert color="success" isOpen={!!successMsg}>
                  {successMsg}
                </Alert>
                <div className="form__form-group mt-3">
                  <Col xs="12" className="mt-3 mb-3">
                    <Alert color="info" className="p-3">
                      <p><span className="bold-text">Information: </span>
                        By confirming the action we will charge your payment method on file and upgrade your account.
                        If you have any questions please reach out to our support team. Thank you!!!
                      </p>
                    </Alert>
                    <h4>Tier: {tierInfo.name}</h4>
                    <h4>Price: {tierInfo.price}</h4>
                    <h4>Frequency: {tierInfo.frequency}</h4>
                  </Col>
                  <button
                    className="btn btn-primary btn-sm btn-block"
                    type="button"
                    onClick={handleTierChange}
                    disabled={errorMsg || processing}
                  >
                    {processing ? (
                      <span>
                        <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                      </span>
                    ) : (
                      'Subscribe'
                    )}
                  </button>
                </div>
              </Col>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default connect(state => ({
  rtl: state.rtl,
  user: state.user.main,
  roles: state.user.roles,
  theme: state.theme,
  users: state.users.users,
  venueData: state.venue.venueData,
  venueMembers: state.venue.members
}))(CardPremium);

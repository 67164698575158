import React, {useEffect, useState} from 'react';
import { Col, Container, Row,Card,CardBody } from 'reactstrap';
import modelVenue from '../../Models/Venue';
import ActionClicks from './components/ActionClicks';
import SubmissionsTable from '../../shared/components/Datatables/SubmissionsTable';
import DatepickerField from './components/DatepickerField';
import PageViews from './components/PageViews';
import { withRouter, useParams } from 'react-router-dom';
import {setVenueStats,setVenueData, getVenueData, setDateRangeEnding, setDateRangeStarting, showVenueMenu} from '../../redux/actions/venueActions';
import {connect} from "react-redux";
import TransactionsTable from "./components/Transactions/TransactionsTable";

const Performance = ({ dispatch, dateRangeEnding, dateRangeStarting, venueData, venueStats }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new modelVenue();
    let latestSubmissionTableOptions = {
        allowModalView:true,
        startDate:dateRangeStarting,
        endDate:dateRangeEnding,

    };

    useEffect(() => {
        dispatch(showVenueMenu(true));

        vm.getVenueData(venueId).then(response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }
            return true;
        });

        vm.getVenueStats(venueId,dateRangeStarting,dateRangeEnding).then(response=>{
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            dispatch(setVenueStats(response.data));
            setIsLoaded(true);
            return true;
        });

        latestSubmissionTableOptions = {
            allowModalView:true,
            startDate:dateRangeStarting,
            endDate:dateRangeEnding,

        };

    },[venueId,dateRangeEnding,dateRangeStarting]);



    return (
        isLoaded && (
            <Container className="dashboard">
                <Row className={"pb-2"}>
                    <Col md={12}>
                        <h3 className="page-title">Performance | {venueData.v_name}</h3>
                        <hr/>
                    </Col>
                    <Col xs={12} md={12} >
                        <Card>
                            <CardBody>
                                <Row className="d-none d-sm-block">
                                    <Col md={12}>
                                        <p style={{display:"inline-block"}}>Date</p>
                                        <DatepickerField
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date)=>dispatch(setDateRangeStarting(date))}
                                            selected={dateRangeStarting}
                                        />
                                        <p style={{display:"inline-block"}}>To</p>
                                        <DatepickerField
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date)=>dispatch(setDateRangeEnding(date))}
                                            selected={dateRangeEnding}
                                        />
                                    </Col>


                                </Row>
                                <Row className="d-block d-sm-none">
                                    <Col xs={12} className="">
                                        <p >Date</p>
                                    </Col>
                                    <Col xs={12} className="p-0">
                                        <DatepickerField
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date)=>dispatch(setDateRangeStarting(date))}
                                            selected={dateRangeStarting}
                                        />
                                        <p style={{display:"inline-block"}}>To</p>
                                        <DatepickerField
                                            dateFormat="MM/dd/yyyy"
                                            onChange={(date)=>dispatch(setDateRangeEnding(date))}
                                            selected={dateRangeEnding}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <PageViews pageViewsData={ venueStats.pageViewsData }/>
                    <ActionClicks eventsData={ venueStats.eventsData } />
                    <SubmissionsTable options={latestSubmissionTableOptions} title={"Latest Submissions"} subTitle={"Based on the last 30 days"} submissionsData={ venueStats.submissions }/>
                    <TransactionsTable />
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    venueData:state.venue.venueData,
    venueStats:state.venue.venueStats,
}))(Performance);
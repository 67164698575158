import React,{ useEffect,useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';

const Contact = ({ dispatch,theme,rtl,submission}) => {

  const history = useHistory();
  const [isLoaded,setLoaded] = useState(false);
  const [categories,setCategories] = useState(false);

  const redirect = (link) =>{
    history.push(link);
  };

  useEffect(() =>{

  },[]);

  return (
      <>
        <Row>
          <div className="form__form-group mb-1">
            <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Full Name</label>
            <div className=" col-sm-12">
              <input name="" type="text" value={submission.name} placeholder="" className="form-control-sm form-control"/>
            </div>
          </div>
        </Row>
        <Row>
          <div className="form__form-group mb-1">
            <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">E-Mail</label>
            <div className=" col-sm-12">
              <input name="" type="text" value={submission.qr_email} placeholder="" className="form-control-sm form-control"/>
            </div>
          </div>
        </Row>
        <Row>
          <div className="form__form-group mb-1">
            <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Phone</label>
            <div className=" col-sm-12">
              <input name="" type="text" value={submission.qr_phone_number} placeholder="" className="form-control-sm form-control"/>
            </div>
          </div>
        </Row>

      </>

  );
};

export default connect(state => ({
  rtl: state.rtl,
  theme: state.theme,
  submission:state.submission.data
}))(Contact);

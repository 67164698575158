import React, {useEffect, useState} from 'react';
import { Col, Container, Row,Card,CardBody } from 'reactstrap';
import ModelVenue from '../../Models/Venue';
import ActionClicks from './components/ActionClicks';
import SubmissionsTable from '../../shared/components/Datatables/SubmissionsTable';
import VenueTier from './components/VenueTier/VenueTier';
import VenueStatus from './components/VenueStatus/VenueStatus';
import {useHistory, withRouter, useParams, Link} from 'react-router-dom';
import {setVenueStats,setVenueData, getVenueData, setDateRangeEnding, setDateRangeStarting, showVenueMenu} from '../../redux/actions/venueActions';
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import StripeContainer from "../../shared/components/StripeContainer";
import CardEditVenue from "../../shared/components/helperCards/CardEditVenue";
import CardGetSupport from "../../shared/components/helperCards/CardGetSupport";
import { faExternalLinkAlt,faUser ,faSignOutAlt,faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const Venue = ({ dispatch, dateRangeEnding, dateRangeStarting, venueData, venueStats }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [link, setLink] = useState(false);
    const vm = new ModelVenue();
    const history = useHistory();

    useEffect(() => {

        vm.getVenueData(venueId).then(response => {

            if (response.data.status === 'failed') {
                alert('Access Denied. You will be re-routed to your profile page');
                history.push("/profile");
                return false;
            }

            let name=response.data.data.v_name;
            let linky = response.data.data.v_name.replace(/[^a-zA-Z0-9 ]/g, "");
            linky = linky.replace(/\s/g , "-");

            //esSearch.filteredResults[index].v_name = esSearch.filteredResults[index].v_name.replace(/[^a-zA-Z0-9 ]/g, "");
            //esSearch.filteredResults[index].v_name = esSearch.filteredResults[index].v_name.replace(/\s/g , "-");

            let venueName= encodeURIComponent(linky) + "-"+response.data.data.v_id;
            // href='/"+esSearch.filteredResults[index].v_city.toLowerCase()+"-"+esSearch.filteredResults[index].v_state.toLowerCase()+"/"+venueName+"'
            // var venueName= encodeURIComponent(link) + "-"+esSearch.filteredResults[index].v_id;
            setLink(process.env.REACT_APP_BOOKWNY_INSTANCE_API_URL+'/'+response.data.data.v_city.toLowerCase()+"-"+response.data.data.v_state.toLowerCase()+"/"+venueName);
            return true;
        });

        vm.getVenueStats(venueId,dateRangeStarting,dateRangeEnding).then(response=>{
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            dispatch(setVenueStats(response.data));
            setIsLoaded(true);
            return true;
        });



    },[venueId,dateRangeEnding,dateRangeStarting]);

    return (
        <>
            {!isLoaded && (
                <div className={`load${isLoaded ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )}
            {isLoaded && (
                <Container className="dashboard">
                    <Row className={"pb-2"}>
                        <Col xs={12} md={12}>
                            <h3 className="page-title">
                                {venueData.v_name} {link !== false && <a href={link} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>}
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <StripeContainer component={VenueTier} venueInfo={venueData} />
                        <VenueStatus />
                        <CardEditVenue />
                        <CardGetSupport />
                    </Row>
                    <Row>
                        <ActionClicks eventsData={venueStats.eventsData} />
                        <SubmissionsTable title="Latest Submissions" limit="20" />
                    </Row>
                </Container>
            )}
        </>
    );
    
}

export default connect(state => ({
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    venueData:state.venue.venueData,
    venueStats:state.venue.venueStats,
}))(Venue);
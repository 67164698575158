import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import UserRoles from "./components/UserRoles";
import { faChartLine,faUser ,faHotel,faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelUser from "../../Models/User";
import {showNotification} from "../../shared/components/Notification";
import MyVenues from "./components/MyVenues";

const Profile = ({ dispatch,venueData, roles,user,theme,rtl }) => {

    const [loaded,setLoaded]=useState(false);
    const um = new ModelUser();



    useEffect(()=>{

        um.getUserInfo().then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }
        });

    },[]);

    useEffect(()=>{
        loadVariablesIntoForm();

        setLoaded(true);
    },[user]);

    const loadVariablesIntoForm=()=>{
        if(typeof user ==="undefined" || user === null)
            return;
        Object.keys(user).forEach(key => {
            document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                el.value = user[key];
            });
            document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                el.value = user[key];
            });
            document.querySelectorAll('select[name='+key+']').forEach((el)=>{
                el.value = user[key];
            });
        });
    };

    const updateProfile = (e) => {
        e.preventDefault();
        let formData = new FormData(document.getElementById('myProfileEditForm'));

        um.updateUser(formData).then((response)=>{
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Update Profile',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update Profile',response.data.msg);
            return;
        });

    };

    return (
        loaded === true &&
            <Container className="dashboard">
                <Row className="justify-content-center pb-2">
                    <Col xs={12} md={6} >
                        <Card>

                            <CardBody>
                                <div className="card__title">
                                    <h5 className="bold-text">My Profile  <FontAwesomeIcon icon={faUser}/></h5>
                                    <h5 className="subhead">Use the form below to update your profile. NOT all fields are allowed to update</h5>
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <form id="myProfileEditForm" className={"form--horizontal form"} onSubmit={updateProfile}>
                                            <div className="form__form-group row mt-1">
                                                <label htmlFor="u_first" className="col-sm-2 form__form-group-label col-form-label">First Name</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control-sm" id="u_first" name="u_first" />
                                                </div>
                                            </div>
                                            <div className="form__form-group row mt-1">
                                                <label htmlFor="u_last" className="col-sm-2 form__form-group-label col-form-label">Last Name</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control-sm" id="u_last" name="u_last" />
                                                </div>
                                            </div>
                                            <div className="form__form-group row mt-1">
                                                <label htmlFor="u_email" className="col-sm-2 form__form-group-label col-form-label">E-Mail</label>
                                                <div className="col-sm-10">
                                                    <input type="email" className="form-control-sm" id="u_email" name="u_email" disabled={'disabled'}/>
                                                </div>
                                            </div>

                                            <div className="form__form-group row mt-1">
                                                <label htmlFor="u_main_phone" className="col-sm-2 form__form-group-label col-form-label">Phone</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control-sm" id="u_main_phone" name="u_main_phone" />
                                                </div>
                                            </div>
                                            <div className="form__form-group row mt-1">
                                                <label htmlFor="u_default_venue_id" className="col-sm-2 form__form-group-label col-form-label">Default Venue</label>
                                                <div className="col-sm-10">
                                                    <select className={"form-control-sm col-12 form-control"} name='u_default_venue_id'>
                                                        {typeof roles !=="undefined" && roles!== null && roles.map((role,i)=>(
                                                            <option key={i} value={role.v_id}>{role.v_name} | {role.r_display_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <button className="btn btn-primary" type="button" onClick={updateProfile}>Update</button>
                                            </div>
                                            
                                        </form>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <MyVenues/>
                </Row>
            </Container>
    );
}

export default connect(state => ({
    venueData:state.venue.venueData,
    roles : state.user.roles,
    user : state.user.main,
    rtl: state.rtl,
    theme: state.theme
}))(Profile);
import React, { useEffect } from 'react';
import {
    Col,
    Card,
    CardImg,
    CardImgOverlay,
    Button
} from "reactstrap";
import Panel from "../../../../shared/components/Panel";
import { connect } from "react-redux";
import modelVenue from '../../../../Models/Venue';

const Images = ({ venueImages, venueData }) => {
    const mv = new modelVenue();
    const fallbackImage = `${process.env.PUBLIC_URL}/img/badFile.jpg`; // Fallback image URL

    useEffect(() => {
        // You can add any effect related to venueImages or venueData here
    }, [venueImages, venueData]);

    const setImageAsFeatured = (e) => {
        e.preventDefault();
        let imageToFeatured = e.target.value;
        let formData = new FormData();
        formData.append('filename', imageToFeatured);
        formData.append('venueId', venueData.v_id);
        mv.setFeaturedImage(formData);
    };

    return (
        <Panel xl={12} lg={12} md={12} title="Images" subhead="Images">
            <div className='row'>
                {venueImages.map((image, index) => (
                    <Col
                        md="3"
                        className="my-2"
                        key={index}
                    >
                        <div className="venueImageCard image-card">
                            <CardImg 
                                src={`${process.env.REACT_APP_VENUE_IMG_ROOT}${image.trim()}`} 
                                onError={(e) => e.target.src = fallbackImage} // Fallback image on error
                                className='venueImage'
                            />
                            <CardImgOverlay className='venueImageCardOverlay'>
                                <Button 
                                    className="btn btn-bwny-secondary" 
                                    onClick={setImageAsFeatured} 
                                    value={image.trim()}
                                >
                                    Set Featured
                                </Button>
                            </CardImgOverlay>
                        </div>
                    </Col>
                ))}
            </div>
        </Panel>
    );
};

export default connect(state => ({
    venueData: state.venue.venueData,
    venueImages: state.venue.venueImages,
}))(Images);

import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import AuthCheck from '../../Services/AuthCheck';
import { setActiveVenueId } from "../../redux/actions/userActions";

const ProtectedRoute = ({ component: Component, user, dispatch, ...rest }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "Y";
    const loginLink = `/login?redirect=${encodeURIComponent(window.location.href)}`;

    useEffect(() => {
        const unlisten = history.listen(() => {
            AuthCheck();
        });

        const initializeAuth = async () => {
            try {
                await AuthCheck();

                let venueId = localStorage.activeVenueId;

                if (!venueId) {
                    if (user.u_default_venue_id) {
                        venueId = user.u_default_venue_id;
                    } else if (user.roles?.length) {
                        venueId = user.roles[0].v_id;
                    } else {
                        history.push("/profile");
                        return;
                    }
                }

                await dispatch(setActiveVenueId(venueId));
            } catch (error) {
                console.error("Authentication initialization failed:", error);
                history.push("/login");
            } finally {
                setIsLoading(false);
            }
        };

        initializeAuth();

        return () => unlisten();
    }, [dispatch, history]);

    if (isLoading) return null; // You can replace this with a loading spinner if desired

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? <Component {...props} /> : <Redirect to={loginLink} />
            }
        />
    );
};

const mapStateToProps = (state) => ({
    user: state.user.main,
    roles: state.user.roles,
    activeVenueId: state.user.activeVenueId,
});

export default connect(mapStateToProps)(ProtectedRoute);

import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PasswordField from '../../../../shared/components/form/Password';

const RegisterUserForm = ({ onSubmit }) => {

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">First Name</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="u_first"
                component="input"
                type="text"
                placeholder="First Name"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Last Name</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="u_last"
                component="input"
                type="text"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Phone Number</span>
            <div className="form__form-group-field">
              <Field
                name="u_main_phone"
                component="input"
                type="text"
                placeholder="Phone Number"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Email Address</span>
            <div className="form__form-group-field">
              <Field
                name="u_email"
                component="input"
                type="email"
                placeholder="Email Address"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <Field
                name="u_password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                keyIcon
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Confirm Password</span>
            <div className="form__form-group-field">
              <Field
                name="con_password"
                component={PasswordField}
                placeholder="Confirm Password"
                className="input-without-border-radius"
                keyIcon
              />
            </div>
          </div>
          <button className="btn btn-primary account__btn account__btn--small" type="submit">
            Sign Up
          </button>
        </form>
      )}
    </Form>
  );
};

export default RegisterUserForm;

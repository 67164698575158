import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import modelVenue from '../../Models/Venue';
import { useParams } from 'react-router-dom';
import {setVenueData, showVenueMenu} from '../../redux/actions/venueActions';
import {connect} from "react-redux";
import Images from "./components/Images";
import ModalUploadImage from "./components/ModalUploadImage";
import {showNotification} from "../../shared/components/Notification";
const badFile = `${process.env.PUBLIC_URL}/img/badFile.jpg`;

const VenuePhotos = ({ dispatch, venueData,venueImages,rtl,theme }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new modelVenue();

    useEffect(() => {
        dispatch(showVenueMenu(true));

        vm.getVenueData(venueId).then(async response => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }
            return true;
        });

        vm.getVenueImages(venueId).then(response => {
            if (response.data.status === "failed") {
                showNotification(theme,'danger',rtl,'Get Images',response.data.msg);
                return false;
            }
            setIsLoaded(true);
            return true;
        });

    },[venueId]);

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row className="justify-content-center">
                    <Col sm={9}>
                        <Col sm={4} className={'mb-4'}>
                            <ModalUploadImage />
                        </Col>
                        {isLoaded &&
                            <Images/>
                        }
                    </Col>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    venueImages:state.venue.venueImages,
    showVenueMenu:state.venue.showVenueMenu
}))(VenuePhotos);
/* eslint-disable */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import RegisterUserForm from './components/RegisterUserForm';
import AuthService from "../../../Services/AuthService";
import { getUrlVars } from "../../../utils/helpers";
import {
    setRoles,
    setUser,
    setActiveVenueId
} from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import { Card, CardBody, Col, Row, Alert, Button } from "reactstrap";
import ModelUsers from '../../../Models/Users';

const bookWNYconfetti = `${process.env.PUBLIC_URL}/img/bookWNYconfetti.mp4`;


const RegisterUser = ({ dispatch, user, activeVenueId }) => {

    const history = useHistory();
    const mu = new ModelUsers();

    const [errorMessage, setErrorMessage] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const handleRegistration = (data) => {

        // Convert data to FormData
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        mu.registerUser(formData).then((res) => {
            if (res.data.status === "failed") {
                // This would be an error
                setErrorMessage(res.data.msg);
            } else {
                // Registration successful
                setErrorMessage('');
                setRegistrationSuccess(true);
                // login the user using the handleLogin function
                const loginCredentials = {
                    username: data.u_email,
                    password: data.u_password,
                };
                handleLogin(loginCredentials);
            }
        }).catch((error) => {
            // Handle error
            setErrorMessage(error.msg);
        });
    };

    const handleLogin = (loginCredentials) => {

        AuthService.login(loginCredentials.username, loginCredentials.password).then(
            async (response) => {
                if (response === false) {
                    setErrorMessage('Login failed');
                    return;
                }

                // SET USER AND ROLES
                dispatch(setUser(response.data.data.user));
                dispatch(setRoles(response.data.data.user.roles));

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setErrorMessage(resMessage);
            }
        );

    };

    return (
        <Row className="justify-content-center pb-2 mt-5">
            <Col xs={12} md={6}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={12}>
                                {/* USE REACTSTRAP ALERT BOX HERE TO SHOW IF THERE ARE ANY ERRORS */}
                                {errorMessage && (
                                    <Alert color="danger" className='p-3'>
                                        {errorMessage}
                                    </Alert>
                                )}
                                {registrationSuccess ? (
                                    <div className="d-flex flex-column align-items-center">
                                        {/* ADD THE CONFETTI MP4 HERE */}
                                        <video width="600px" height="auto" autoPlay loop muted>
                                            <source src={bookWNYconfetti} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <h5 className="mt-3">Welcome to bookWNY!</h5>
                                        <p>Your account has been successfully created.</p>
                                        <Button color="primary" onClick={() => history.push('/profile')}>Go to your Profile</Button>
                                    </div>
                                ) : (
                                    <>
                                        <div className="account__head">
                                            <h3 className="account__title">bookWNY <span className="account__logo">ADMIN</span></h3>
                                            <h4 className="account__subhead subhead">Manage your business easily</h4>
                                        </div>
                                        <RegisterUserForm onSubmit={handleRegistration} />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
    

};

export default connect(state => ({
    user: state.user.main,
    roles: state.user.roles,
    activeVenueId: state.user.activeVenueId
}))(RegisterUser);

import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {connect} from "react-redux";
import ModelSubmission from "../../Models/Submission";
import {useParams} from "react-router-dom";
import Conversation from "./components/Conversation";
import SubmissionDetails from "./components/SubmissionDetails";
import Contact from "../../shared/components/Contact";
import classnames from "classnames";
import SubmissionNotes from "../../shared/components/SubmissionNotes";

const Submission = ({ dispatch,submission,conversation }) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const { submissionId } = useParams();
    const sm = new ModelSubmission();
    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        sm.getFullSubmission(submissionId).then(()=>{
            setIsLoaded(true);

        });

    },[submissionId]);

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <Card>
                        <CardBody className="pt-0">

                            <Row className="">
                                <Col xs={3} className="border-right">
                                    <SubmissionDetails/>
                                </Col>
                                <Col xs={6} className="border-right">
                                    <Conversation/>
                                </Col>
                                <Col xs={3} className="pl-1 pr-1 pt-3">
                                    <div className="tabs tabs--bordered-top">
                                        <div className="tabs__wrap">
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => toggle('1')}>Contact</NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => toggle('2')}>Notes</NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <Col xs={12}>
                                                        <Contact/>
                                                    </Col>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Col xs={12} className={""}>
                                                        <SubmissionNotes/>
                                                    </Col>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </CardBody>

                    </Card>


                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    submission:state.submission.data,
    conversation:state.submission.conversation,
}))(Submission);
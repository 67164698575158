import React,{ useEffect,useState } from 'react';
import Panel from "../../Panel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../../Models/Submissions';
import {Link, useHistory,useParams } from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../DataTable";

const SubmissionsTable = ({ dispatch, venues,venueData, submissions,title, limit, activeVenueId}) => {

    const { venueId } = useParams();
    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const um = new ModelSubmissions();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof submissions !== 'undefined' || submissions!==null){
            setLoaded(true);
        }
    },[submissions,venueData]);

    const columns =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"View/Edit"}>
                            <button className={ "btn btn-bwny-primary btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1"} onClick={() => redirect("/v/"+activeVenueId+"/s/"+cell.row.original.qr_id)}>
                                <FontAwesomeIcon icon={faEye}/>
                            </button>
                        </Tooltip>
                        <Tooltip placement="top" overlay={"View in New Tab"}>
                            <Link
                                to={"/v/"+activeVenueId+"/s/"+cell.row.original.qr_id}
                                target="_blank"
                            >
                                <button type={"button"} className={ "btn btn-bwny-primary btn-sm m-0 mr-2 mt-1 pt-1 pb-1 pl-2 pr-1"}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </button>
                            </Link>
                        </Tooltip>
                    </>
                )
            }
        },{
            Header: 'ID',
            accessor: 'qr_id',
            width: 100
        }
        // ,{
        //     width: 300,
        //     Header: "Venue",
        //     accessor: "v_name",
        //     filter: "default"
        // }
        ,{
            Header: 'First',
            accessor: 'qr_name',
            filter: "default"

        }
        ,{
            Header: 'Last',
            accessor: 'qr_last_name',
            filter: "default"

        },{
            Header: 'Budget',
            accessor: 'qr_event_budget_number',
            filter: "numberRange"
        },{
            Header: '# of People',
            accessor: 'qr_num_of_people'
        },{
            Header: 'Event Date',
            accessor: 'qr_event_date',
            filter: "dateRange"
        },{
            Header: 'Event Type',
            accessor: 'et_name'
        },{
            Header: 'Email',
            accessor: 'qr_email'
        },{
            Header: 'Created Date',
            accessor: 'qr_created_date'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (

        <Panel xl={12} lg={12} md={12} title={title??"Opportunities"} subhead="Table of the latest submissions">
            <div>
                <DataTable columns={columns} endpoint={"v/"+venueId+"/ss/getSubmissionsV2"}/>
            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues,
    venueData:state.venue.venueData,
    submissions:state.submissions.submissions,
    activeVenueId : state.user.activeVenueId
}))(SubmissionsTable);

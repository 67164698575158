import { handleActions } from 'redux-actions';
import {
    setDateRangeStarting,
    getVenueData,
    setDateRangeEnding,
    showVenueMenu,
    setVenueData,
    setVenueStats,
    setVenueImages,
    setVenueMembers,
    setVenueSubscriptions
} from '../actions/venueActions';

const today = new Date();
console.log(today.toLocaleDateString("en-US"),new Date(new Date().setDate(today.getDate() - 30)).toLocaleDateString("en-US"));
const defaultState = {
    dateRangeEnding : today,
    dateRangeStarting : new Date(new Date().setDate(today.getDate() - 30)),
    venueData: [],
    venueStats: [],
    showVenueMenu: false
};

export default handleActions(
  {
    [setDateRangeStarting](state, { payload }) {
      return { ...state, dateRangeStarting: payload };
    },
    [setDateRangeEnding](state, { payload }) {
      return { ...state, dateRangeEnding: payload };
    },
    [getVenueData](state, { payload }) {
      return { ...state, venueData: payload };
    },
    [setVenueData](state, { payload }) {
      return { ...state, venueData: payload };
    },
    [setVenueStats](state, { payload }) {
      return { ...state, venueStats: payload };
    },
    [showVenueMenu](state, { payload }) {
      return { ...state, showVenueMenu: payload };
    },
    [setVenueImages](state, { payload }) {
      return { ...state, venueImages: payload };
    },
    [setVenueMembers](state, { payload }) {
      return { ...state, members: payload };
    },
    [setVenueSubscriptions](state, { payload }) {
      return { ...state, subscriptions: payload };
    }
  },
  defaultState,
);

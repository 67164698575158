import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';

const reorder = (rows, startIndex, endIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({ reactTableData, classes }) => {
  const [rows, setData] = useState(reactTableData.tableRowsData);
  const [isResizable, setIsResizable] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(false);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const updateDraggableData = (result) => {
    const items = reorder(
      rows,
      result.source.index,
      result.destination.index,
    );
    setData(items);
  };

  const updateEditableData = (rowIndex, columnId, value) => {
    setData(old => old.map((item, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return item;
    }));
  };

  const tableConfig = {
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search all ...',
  };

  return (
      <div>
        {/*<div className="react-table__wrapper"></div>*/}
        <ReactTableBase
          key={withSearchEngine || isResizable ? 'modified' : 'common'}
          columns={reactTableData.tableHeaderData}
          data={rows}
          tableConfig={tableConfig}
          classes = {classes}
        />
      </div>
  );
};

// DataReactTable.propTypes = {
//   reactTableData: PropTypes.shape({
//     tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
//       key: PropTypes.string,
//       name: PropTypes.string,
//     })),
//     tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
//     defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
//   }).isRequired,
// };

export default DataReactTable;

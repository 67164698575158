import { handleActions } from 'redux-actions';
import {
    setAllSubmissions
} from '../actions/submissionsActions';

const defaultState = {

};

export default handleActions(
  {
    [setAllSubmissions](state, { payload }) {
        return {...state, submissions: payload};
    }
  },
  defaultState,
);

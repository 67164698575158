import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModelVenue from '../../Models/Venue';
import { useParams } from 'react-router-dom';
import { getVenueData ,showVenueMenu} from '../../redux/actions/venueActions';
import classnames from 'classnames';
import {connect} from "react-redux";
import Panel from "../../shared/components/Panel";
import {showNotification} from "../../shared/components/Notification";
import StripeContainer from "../../shared/components/StripeContainer";
import SubscriptionsComponent from "./components/SubscriptionsComponent";

const Subscriptions = ({ dispatch, venueData,rtl,theme }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(true);
    const vm = new ModelVenue();
    const [activeTab, setActiveTab] = useState('1');


    // if(venueData.vt_ids == null || venueData.vt_ids.length === 0 || typeof venueData.vt_ids === "undefined")
    //     return;



    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        dispatch(showVenueMenu(true));

        // if(!isLoaded){
        //     vm.getVenueData(venueId).then(async response => {
        //
        //         if (response.data.status === 'failed') {
        //             alert('failed');
        //             return false;
        //         }
        //
        //
        //         await setIsLoaded(true);
        //         return true;
        //     });
        // }


    },[venueId,isLoaded]);

    return (

        isLoaded && (
            <Container className="dashboard">
                <Row className="justify-content-center">
                    <Panel sm={8}  title={venueData.v_name} refreshFunction="">
                        <div>
                            <StripeContainer component={SubscriptionsComponent}/>
                        </div>
                    </Panel>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(Subscriptions);
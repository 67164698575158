/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import AuthService from "../../../Services/AuthService";
import {getUrlVars } from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import {
    setRoles,
    setUser,
    setActiveVenueId
} from "../../../redux/actions/userActions";
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";

const LogIn = ({dispatch,user,activeVenueId}) => {

    const history = useHistory();

    const handleLogin = (loginCredentials) => {

        AuthService.login(loginCredentials.username, loginCredentials.password).then(
            async (response) => {
                if(response===false)
                    return;

                // SET USER AND ROLES
                dispatch(setUser(response.data.data.user));
                dispatch(setRoles(response.data.data.user.roles));

                // if(localStorage.getItem("activeVenueId")===null){
                //     if(response.data.data.user.u_default_venue_id !== null){
                //         await dispatch(setActiveVenueId(response.data.data.user.u_default_venue_id));
                //     }else{
                //         if(response.data.data.user.hasOwnProperty('roles')){
                //             debugger
                //         }
                //     }
                // }else
                //     await dispatch(setActiveVenueId(localStorage.getItem("activeVenueId")));


                if(response.data.data.user.u_default_venue_id !== null){
                    await dispatch(setActiveVenueId(response.data.data.user.u_default_venue_id));
                }else if(response.data.data.user.hasOwnProperty('roles')){
                    await dispatch(setActiveVenueId(response.data.data.user.roles[0].v_id));
                }else{
                    // THE USER HAS NO ROLES OR DEFAULT VENUE ID
                    history.push("/profile");
                }

                // CHECK FOR REDIRECT LINK
                // Read a page's GET URL variables and return them as an associative array.
                let vars = getUrlVars();
                if(typeof vars !== "undefined" && vars !== null){
                    if(typeof vars['redirect'] !== "undefined"){
                        window.location.href = vars['redirect'];
                        return;
                    }
                }

                history.push("/v/"+localStorage.getItem("activeVenueId"));

            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );

    };
    {/*<div className="account">*/}
        {/*<div className="account__wrapper">*/}
            {/*<div className="account__card">*/}
                {/*<div className="account__head">*/}
                    {/*<h3 className="account__title">bookWNY <span className="account__logo">ADMIN</span></h3>*/}
                    {/*<h4 className="account__subhead subhead">Manage your business easily</h4>*/}
                {/*</div>*/}
                {/*<LogInForm onSubmit={handleLogin}/>*/}
            {/*</div>*/}
        {/*</div>*/}
    {/*</div>*/}

    const goToRegisterAccount = () => {
        history.push("/register");
    };

    return (

        <Row className="justify-content-center pb-2 mt-5">
            <Col xs={12} sm={5} md={5} lg={4} xl={3}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={12} className='text-center'>
                                    <img 
                                        src="https://bookwny.com/assets/img/logos/BookWNY_Logo_Scroll.png" 
                                        alt="bookWNY Logo" 
                                        className="mb-4" 
                                        style={{ width: '200px' }}
                                    />
                            </Col>
                            <Col sm={12}>
                                {/* <div className="account__head">
                                    <h3 className="account__title">bookWNY <span className="account__logo">ADMIN</span></h3>
                                    
                                    <h4 className="account__subhead subhead">Manage your business easily</h4>
                                </div> */}
                                <LogInForm onSubmit={handleLogin}/>

                            </Col>
                            <Col sm={12}>
                                <button className="btn btn-bwny-primary btn-block" type="submit" onClick={goToRegisterAccount}>Create an Account</button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    );

};

export default connect(state => ({
    user : state.user.main,
    roles : state.user.roles,
    activeVenueId : state.user.activeVenueId
}))(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/component/form.scss to add styles

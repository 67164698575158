import axios from 'axios';
import AuthService from '../../Services/AuthService';

const Http = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL
});

Http.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);

Http.interceptors.response.use(
    response => response,
    async (error) => {
        const url = window.location.href;
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
            prevRequest.sent = true;
            await AuthService.refreshToken().then(response=>{
                if(!response){
                    if(typeof url !== "undefined")
                        window.location.replace("/login?redirect="+url);
                    else
                        window.location.replace("/login");

                }

            });
            prevRequest.headers['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
            return Http(prevRequest);
        }
        return Promise.reject(error);
    }
);

export default Http;
import React, { useState } from 'react';
import {useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect, } from 'react-table';
import { useExportData } from 'react-table-plugins';
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ReportingTable = ({ reactTableData, classes }) => {
    const [data, setData] = useState(reactTableData.tableRowsData);
    const columns = reactTableData.tableHeaderData;
    const [isResizable, setIsResizable] = useState(true);
    const [isSortable, setIsSortable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(false);
    const [withSearchEngine, setWithSearchEngine] = useState(true);

    const getExportFileBlob = ({ columns, data, fileType, fileName }) => {
        if (fileType === "csv") {
            // CSV example
            const headerNames = columns.map((col) => col.exportValue);
            const csvString = Papa.unparse({ fields: headerNames, data });
            return new Blob([csvString], { type: "text/csv" });
        } else if (fileType === "xlsx") {
            // XLSX example

            const header = columns.map((c) => c.exportValue);
            const compatibleData = data.map((row) => {
                const obj = {};
                header.forEach((col, index) => {
                    // IF IS DATE THE USE DATE OBJ
                    if(!isNaN(Date.parse(row[index])) && isNaN(row[index]) && /[a-zA-Z]/.test(row[index]) === false){
                        obj[col] = new Date(row[index]);

                    }

                    else
                        obj[col] = row[index];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        //PDF example
        if (fileType === "pdf") {
            const headerNames = columns.map((column) => column.exportValue);
            const doc = new JsPDF();
            doc.autoTable({
                head: [headerNames],
                body: data,
                margin: { top: 20 },
                styles: {
                    minCellHeight: 9,
                    halign: "left",
                    valign: "center",
                    fontSize: 11,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }

        // Other formats goes here
        return false;
    }

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };
    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };
    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setData(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setData(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const tableConfig = {
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search all ...',
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        exportData,
    } = useTable({
        columns,
        data,
        getExportFileBlob
        },
        useFilters,
        useSortBy,
        useExportData
    );

    // console.log(reactTableData);

    return (
        <div>
            <div id={"reportingTableActions"}>
                <button className={"btn btn-sm btn-secondary"}
                        onClick={() => {
                            exportData("csv", true);
                        }}
                >
                    Export All as CSV
                </button>
                <button className={"btn btn-sm btn-secondary"}
                        onClick={() => {
                            exportData("csv", false);
                        }}
                >
                    Export Current View as CSV
                </button>
                <button className={"btn btn-sm btn-secondary"}
                        onClick={() => {
                            exportData("xlsx", true);
                        }}
                >
                    Export All as xlsx
                </button>
                <button className={"btn btn-sm btn-secondary"}
                        onClick={() => {
                            exportData("xlsx", false);
                        }}
                >
                    Export Current View as xlsx
                </button>
                <button className={"btn btn-sm btn-secondary"}
                        onClick={() => {
                            exportData("pdf", true);
                        }}
                >
                    Export All as PDF
                </button>{" "}
                <button className={"btn btn-sm btn-secondary"}
                        onClick={() => {
                            exportData("pdf", false);
                        }}
                >
                    Export Current View as PDF
                </button>
            </div>
            <div className={withPagination ? 'table react-table '+ classes : 'table react-table table--not-pagination '+ classes }>

            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                <div>
                                    {column.canGroupBy ? (
                                        // If the column can be grouped, let's add a toggle
                                        <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? '🛑 ' : '👊 '}
                      </span>
                                    ) : null}
                                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                    </span>
                                </div>
                                {/* Render the columns filter UI */}
                                {/*<div>{column.canFilter ? column.render('Filter') : null}</div>*/}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
        </div>
    );
};

export default ReportingTable;


import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelSubmissions from '../../../../Models/Submissions';
import {
    Alert,
    Button,
    ButtonToolbar,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Row,
    Col,
    Collapse,
    UncontrolledCollapse
} from 'reactstrap';

const Conversation = ({dispatch, rtl,submission,conversation}) => {


    useEffect(() => {

    },[submission]);

    return (
        <div>
            <Row className="border-bottom pt-3 pb-3 mb-3">
                <Col xs={12}>
                    <h3>{submission.name}</h3>
                </Col>
            </Row>
            {conversation !== null &&
                conversation.map((item,index)=>(
                    <Row>
                        {(item.cms_from === submission.qr_email) &&
                            <Col xs={12} className={"text-left pb-4"} >
                                <Row>

                                    <Col xs={{ size: 9, offset: 2 }} className="alert-info" style={{borderRadius:"15px 0px 15px 15px"}}>
                                        {(item.cm_id ==="1"||item.cm_id ===1) &&
                                        <>
                                            <p><b>E-Mail</b><br/>{item.cms_from}<br/><b>{item.cms_subject}</b></p>
                                            <Button
                                                className={"btn-sm email-view-toggler-"+index}
                                                color="primary"
                                            >View</Button>
                                            <UncontrolledCollapse toggler={".email-view-toggler-"+index}>
                                                <div dangerouslySetInnerHTML={{__html: item.cms_content_raw}}></div>
                                            </UncontrolledCollapse>
                                        </>
                                        }
                                    </Col>
                                    <Col xs={1} className="p-2 text-center">
                                        <p style={{background:"#7fffd4",borderRadius:"30px",padding:"10px"}}><b>{item.cms_from.substring(0,1).toUpperCase()}</b></p>
                                    </Col>
                                </Row>
                            </Col>
                        }

                        {(item.cms_from !== submission.qr_email) &&
                            <Col xs={12} className={"text-left pb-4"} >
                                <Row>
                                    <Col xs={1} className="p-2 text-center">
                                        <p style={{background:"#7fffd4",borderRadius:"30px",padding:"10px"}}><b>{item.cms_from.substring(0,1).toUpperCase()}</b></p>
                                    </Col>
                                    <Col xs={9} className="alert-secondary" style={{borderRadius:"0px 15px 15px 15px"}}>
                                        {(item.cm_id ==="1"||item.cm_id ===1) &&
                                        <>
                                            <p><b>E-Mail</b><br/>{item.cms_from}<br/><b>{item.cms_subject}</b></p>
                                            <Button
                                                className={"btn-sm email-view-toggler-"+index}
                                                color="primary"
                                            >View</Button>
                                            <UncontrolledCollapse toggler={".email-view-toggler-"+index}>
                                                <div dangerouslySetInnerHTML={{__html: item.cms_content_raw}}></div>
                                            </UncontrolledCollapse>
                                        </>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        }

                    </Row>
                ))
            }
            {(conversation.length ===0 ||  conversation.length === null) &&
                <h1>No msgs</h1>
            }
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    users:state.users.users,
    submission:state.submission.data,
    conversation:state.submission.conversation,
}))(Conversation);
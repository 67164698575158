import Http from '../containers/App/Http';
import store from '../containers/App/store';
import {setVenues} from "../redux/actions/venuesActions";

export default class Venues{

    async getVenues(){
        // FETCH THE VENUE INFO
        let formData = new FormData();
        formData.append("yo","mama");
        return Http.post("/vs/getVenues",formData)
            .then(async response => {
            if (response.data.status === 'failed') {
                // alert('failed');
                return false;
            }
            await store.dispatch(setVenues(response.data));
            return true;
        });
    };

    createVenueByGooglePlaceId(placeId){
        const formData = new FormData();
        formData.append('placeId',placeId);
        return Http.post("/vs/createNewVenueFromGooglePlaceID",formData)
            .then(async response => {
                return response;
            });
    }

    async createVenueByManualForm(venueData) {
        const formData = new FormData();
        // Append all necessary fields with keys matching backend expectations
        formData.append('b_type_id', venueData.b_type_id); // Changed from 'businessType' to 'b_type_id'
        formData.append('v_name', venueData.v_name); // Changed from 'businessName' to 'v_name'
        formData.append('v_address', venueData.v_address); // Changed from 'address' to 'v_address'
        formData.append('v_city', venueData.v_city); // Changed from 'city' to 'v_city'
        formData.append('v_state', venueData.v_state); // Changed from 'state' to 'v_state'
        formData.append('v_zip', venueData.v_zip); // Changed from 'zip' to 'v_zip'
        formData.append('v_website', venueData.v_website || ''); // Changed from 'website' to 'v_website'
        formData.append('v_phone', venueData.v_phone); // Changed from 'phoneNumber' to 'v_phone'
        formData.append('v_min_budget', venueData.v_min_budget || ''); // Changed from 'minPrice' to 'v_min_budget'
        formData.append('v_max_capacity', venueData.v_max_capacity); // Changed from 'maxCapacity' to 'v_max_capacity'

        return Http.post("/vs/submitNewBusinessAccount", formData)
            .then(async response => {
                return response;
            })
            .catch(error => {
                throw error;
            });
    }

    searchForVenuesV2(searchObj) {
        const formData = new FormData();
        formData.append("search", JSON.stringify(searchObj));
        
        // Make the API call
        return Http.post("/vs/searchForVenuesV2", formData)
            .then(async response => {
                return response;
            });
    }

    createBlankVenue(){

        return Http.post("/vs/createNewVenue")
            .then(async response => {
                return response;
            });
    }
}
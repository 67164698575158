import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Col,
  Modal,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Http from '../../../../src/containers/App/Http';
import { setActiveVenueId } from "../../../redux/actions/userActions";
import ModelUser from '../../../Models/User';

const RequestAccess = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { rtl } = useSelector(state => ({
    rtl: state.rtl,
  }));

  const [errorMsg, setError] = useState('');
  const [successMsg, setSuccess] = useState('');
  const [processing, setProcessing] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  // Initialize ModelUser only once
  const userModel = useMemo(() => new ModelUser(), []);

  const roleOptions = useMemo(() => [
    { value: 'REGU', label: 'User' },
    { value: 'VADM', label: 'Venue Admin' },
  ], []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    setError('');
    setSuccess('');

    const formData = new FormData();
    if (selectedVenue) formData.append('venueId', selectedVenue.value);
    if (selectedRole) formData.append('roleId', selectedRole.value);

    try {
      const response = await Http.post("/u/addUserRole", formData);
      const { status, msg, data } = response.data;

      if (status === 'failed') {
        setError(msg);
      } else {
        userModel.setUserRedux(data, data.roles || []);
        if (data.roles && data.roles.length) {
          dispatch(setActiveVenueId(data.roles[0].v_id));
        }
        setSuccess(msg);
      }
    } catch {
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  const loadVenueOptions = useCallback(async (inputValue, callback) => {
    if (inputValue.trim().length < 2) {
      callback([]);
      return;
    }

    const formData = new FormData();
    formData.append('search', inputValue);

    try {
      const response = await Http.post('/vs/searchForVenues', formData);
      const { status, data } = response.data;

      if (status === 'failed') {
        setError(response.data.msg);
        callback([]);
      } else {
        // Directly use the data as it already contains 'label' and 'value'
        callback(data);
      }
    } catch {
      setError('Failed to load venues. Please try again.');
      callback([]);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog--primary modal-dialog--header"
      id="ModalRequestAccess"
      modalClassName={`${rtl.direction}-support`}
      size="md"
      aria-labelledby="requestAccessModalTitle"
      role="dialog"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          aria-label="Close"
          type="button"
          onClick={toggle}
        />
        <h4 className="text-modal modal__title" id="requestAccessModalTitle">
          Request Access
        </h4>
      </div>

      <div className="modal__body">
        {successMsg ? (
          <div>
            <Alert color="success">{successMsg}</Alert>
            <Button color="primary" size="sm" onClick={toggle}>
              Close <FontAwesomeIcon icon="user" />
            </Button>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Col xs="12">
              {errorMsg && <Alert color="danger" className="p-3">{errorMsg}</Alert>}
              <Alert color="info" className="p-3">
                <p><strong>Information:</strong> Request will be reviewed and you'll be notified once access is granted.</p>
              </Alert>
            </Col>

            <Col xs="12" className="mb-3">
              <FormGroup>
                <Label for="venueSelect">Search Venues:</Label>
                <small className="d-block">Start typing venue name and select from the list.</small>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadVenueOptions}
                  defaultOptions
                  onChange={setSelectedVenue}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Select a venue..."
                  id="venueSelect"
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label for="roleSelect">Select Role:</Label>
                <small className="d-block"><strong>Select from the dropdown options.</strong></small>
                <Select
                  isSearchable
                  options={roleOptions}
                  onChange={setSelectedRole}
                  value={selectedRole}
                  placeholder="Select a role..."
                  id="roleSelect"
                />
              </FormGroup>
            </Col>

            <Col xs="12" className="mb-3">
                <p>* By submitting this request you certify that you are a member/officer of the business selected</p>
            </Col>

            <Col xs="12" className="d-flex justify-content-end">
              <Button
                color="primary"
                size="sm"
                type="submit"
                disabled={processing}
                className="mr-2"
              >
                {processing ? 'Submitting...' : 'Submit'}
              </Button>
              <Button
                color="secondary"
                size="sm"
                onClick={toggle}
                disabled={processing}
              >
                Close
              </Button>
            </Col>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default RequestAccess;

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimesCircle, faCheckCircle, faCheckSquare, faCoffee, faEdit } from '@fortawesome/fontawesome-free-solid';
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';
import img from '../../../img/helperCardImages/editVenueImage.png';

// VENUE INFORMATION IS IN venueData
// example venueData.v_name is the venue name

const CardEditVenue = ({dispatch, venueData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStatus,setActiveStatus] = useState("Not Available");
  const history = useHistory();

  useEffect(()=>{
    // You can add any side effects or logic here
  },[venueData]);

  const handleEdit = () => {
    history.push(`/edit/${venueData.v_id}`);
  }

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget  p-3 d-flex flex-column">
          <div className="card__title">
            <h5 className="bold-text">Edit Venue</h5>
          </div>
          <div className="card__body flex-grow-1">
            <Row>
              <Col xs={3}>
                <img src={img} alt="Edit Venue" />
              </Col>
              <Col xs={9}>
                <p>
                  Ensure venue details are up to date.
                </p>
              </Col>
              
            </Row>
            <Row>
              
            </Row>
          </div>
          <div className="mt-auto">
              <Button color="" className="btn-block btn-bwny-primary btn-sm mb-0" onClick={handleEdit}>
                <FontAwesomeIcon icon={faEdit} /> Edit Venue Details
              </Button>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default connect(state => ({
  venueData: state.venue.venueData
}))(CardEditVenue);
// ./src/containers/AccountManagement/NewVenueWorkflow/index.jsx

import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import AuthCheck from '../../../Services/AuthCheck';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { 
    Card, 
    CardBody, 
    Col, 
    Row, 
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Alert, 
    Table 
} from "reactstrap";
import modelVenues from '../../../Models/Venues'; // Importing Venues model
import modelUser from '../../../Models/User'; // Importing User model
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
    setRoles,
    setUser,
    setActiveVenueId
} from "../../../redux/actions/userActions";

const libraries = ['places']; // Load the Places library

const NewVenueWorkflow = ({ dispatch, user, activeVenueId }) => {
    const [isLoggedIn, setUserLoggedIn] = useState(false);
    const [availableVenues, setAvailableVenues] = useState([]); // To store venues found by search
    const [isLoading, setIsLoading] = useState(true);
    const [showAddVenueForm, setShowAddVenueForm] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(''); // State for success messages
    const [isAddingVenue, setIsAddingVenue] = useState(false); // State to handle adding venue
    const [venueExists, setVenueExists] = useState(false); // New state to track 409 error
    const [venueId, setVenueId] = useState(null); // New state to track 409 error

    // Updated manualFormData with backend-expected keys
    const [manualFormData, setManualFormData] = useState({
        b_type_id: '',
        v_name: '',
        v_address: '',
        v_city: '',
        v_state: '',
        v_zip: '',
        v_website: '',
        v_phone: '',
        v_min_budget: '',
        v_max_capacity: ''
    });

    const [isSubmittingManualForm, setIsSubmittingManualForm] = useState(false); // State for manual form submission

    const autocompleteRef = useRef(null); // Using useRef for Autocomplete instance
    const history = useHistory();
    const url = window.location.href;
    const vsm = new modelVenues();
    const um = new modelUser();

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const userResponse = await AuthCheck();
                if (userResponse.data.status === "success") {
                    setUserLoggedIn(true);
                }
            } catch (authError) {
                console.error("Authentication initialization failed:", authError);
                // Optionally redirect to login
                // history.push("/login?redirect=" + url);
            } finally {
                setIsLoading(false);
            }
        }

        initializeAuth();
    }, [url, history]);

    const onLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    };

    const onPlaceChanged = () => {
        if (autocompleteRef.current !== null) {
            const place = autocompleteRef.current.getPlace();
            if (place && place.place_id && place.name && place.formatted_address) {
                setSelectedPlace({
                    name: place.name,
                    placeId: place.place_id,
                    address: place.formatted_address,
                });
                setShowAddVenueForm(false); // Hide manual form if a place is selected
                setError('');
                setSuccess('');
                setVenueExists(false); // Reset venueExists in case it was previously true
                setAvailableVenues([]); // Reset availableVenues
            } else {
                setError("Please select a valid business from the suggestions.");
                setSelectedPlace(null);
                setVenueExists(false);
                setAvailableVenues([]);
                
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
            setError("Autocomplete is not loaded yet. Please try again.");
            setVenueExists(false);
            setAvailableVenues([]);
        }
    };

    const handleManualAdd = () => {
        setShowAddVenueForm(true);
        setSelectedPlace(null); // Reset any selected place
        setError('');
        setSuccess('');
        setAvailableVenues([]);
    };

    // Optional: Reset selectedPlace if input changes without selection
    const handleInputChange = () => {
        setSelectedPlace(null);
        setError('');
        setSuccess('');
        setVenueExists(false);
        setAvailableVenues([]);
    };

    // Handler for "Add My Venue" button
    const handleAddMyVenue = async () => {
        if (!selectedPlace) {
            setError("No place selected to add.");
            return;
        }
    
        setIsAddingVenue(true);
        setError('');
        setSuccess('');
        setVenueExists(false); // Reset venueExists before attempting to add
        setAvailableVenues([]); // Reset availableVenues
         // Reset selectedAvailableVenue
    
        try {
            // Step 1: Search for existing venues by Google Place ID
            let searchObj = {
                'v_google_place_id': selectedPlace.placeId
            }
                
            const searchResponse = await vsm.searchForVenuesV2(searchObj);
    
            // Assuming the API returns the venues in searchResponse.data.data
            const venuesFound = searchResponse.data.data;
    
            if (venuesFound.length === 0) {
                // No venues found, proceed to create a new venue
                try {
                    const createResponse = await vsm.createVenueByGooglePlaceId(selectedPlace.placeId);
                    
                    setVenueId(createResponse.data.data.v_id);

                    setSuccess('Venue added successfully!');
                    // Optionally, redirect to another page or reset the form
                    // history.push(`/venues/${createResponse.data.venueId}`);
                } catch (createError) {
                    if (createError.response) {
                        if (createError.response.status === 409) {
                            // Handle 409 Conflict - Venue already exists
                            setError('Venue already exists. Get access to your venue.');
                            setVenueExists(true); // Set venueExists to true to display the button
                        } else {
                            // Handle other failures based on your API's response structure
                            setError(createError.response.data.msg || 'Unable to add venue. Please try again.');
                            setVenueExists(false);
                        }
                    } else {
                        console.error('Error adding venue:', createError);
                        setError('Failed to add venue. Please try again.');
                        setVenueExists(false);
                    }
                }
            } else {
                // Venues found (one or multiple), display them in a table
                setAvailableVenues(venuesFound);
            }
        } catch (searchError) {
            console.error('Error searching for venues:', searchError);
            setError('Error searching for venues. Please try again.');
        } finally {
            setIsAddingVenue(false);
        }
    };

    // Handler for manual form field changes
    const handleManualFormChange = (e) => {
        const { name, value } = e.target;
        setManualFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handler for manual form submission
    const handleManualFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmittingManualForm(true);
        setError('');
        setSuccess('');

        try {
            const createResponse = await vsm.createVenueByManualForm(manualFormData);
            
            if (createResponse.data.status === 'success') {
                setVenueId(createResponse.data.data.v_id);
                setSuccess('Venue added successfully!');
                setShowAddVenueForm(false);
                setManualFormData({
                    b_type_id: '',
                    v_name: '',
                    v_address: '',
                    v_city: '',
                    v_state: '',
                    v_zip: '',
                    v_website: '',
                    v_phone: '',
                    v_min_budget: '',
                    v_max_capacity: ''
                });
                // Optionally, you can dispatch actions or redirect
            } else {
                setError(createResponse.data.msg || 'Unable to add venue. Please try again.');
            }
        } catch (createError) {
            if (createError.response) {
                setError(createError.response.data.msg || 'Failed to add venue. Please try again.');
            } else {
                console.error('Error adding venue:', createError);
                setError('Failed to add venue. Please try again.');
            }
        } finally {
            setIsSubmittingManualForm(false);
        }
    };

    // Helper function to request access to a venue
    const requestAccessToVenue = async (venue) => {
        setIsAddingVenue(true);
        setError('');
        setSuccess('');

        try {
            const formData = new FormData();
            formData.append('venueId', venue.v_id);
            formData.append('roleId', "VADM");
            await um.addUserRole(formData);
            setVenueId(venue.v_id);
            await dispatch(setActiveVenueId(venue.v_id));
            setSuccess('Access requested successfully!');
            // Optionally, reset selectedAvailableVenue and availableVenues
            setAvailableVenues([]);
            
        } catch (accessError) {
            if (accessError.response) {
                setError(accessError.response.data.msg || 'Failed to request access. Please try again.');
            } else {
                console.error('Error requesting access:', accessError);
                setError('Failed to request access. Please try again.');
            }
        } finally {
            setIsAddingVenue(false);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>; // Show loading while checking authentication
    }

    return (
        <Row className="justify-content-center pb-2 mt-5">
            <Col xs={12} md={8}>
                <Card>
                    <CardBody>
                        <Row>
                            {isLoggedIn ? (
                                (
                                    // Venue Setup Workflow
                                    <>
                                        <Col sm={12}>
                                            <img 
                                                src="https://bookwny.com/assets/img/logos/BookWNY_Logo_Scroll.png" 
                                                alt="bookWNY Logo" 
                                                className="mb-4" 
                                                style={{ width: '200px' }}
                                            />
                                        </Col>
                                        <Col sm={12}>
                                        <p>Thank you for your interest in bookWNY. The below will walk you through the process of onboarding your venue to the bookWNY platform.<b> At any point you require assistance, call us at <a href="tel:716-309-5193">716-309-5193</a> or contact us using the contact form found <a href="https://bookwny.com/contact-us">here</a>.</b></p>
                                        <hr></hr>
                                        </Col>
                                        {/* Display Success Section */}
                                        {success && (
                                                <Col className="col-12 welcome-section p-4 text-center">
                                                    <h2>Welcome to bookWNY!</h2>
                                                    <p>
                                                        Thank you for adding your venue. We're excited to have you on board! Manage your events, bookings, and more from your dashboard.
                                                    </p>
                                                    <Button 
                                                        color="primary" 
                                                        onClick={() => history.push(`/v/${venueId}`)}
                                                    >
                                                        Go to Dashboard
                                                    </Button>
                                                </Col>
                                        )}

                                        <Col sm={12}>

                                        {success === "" && (
                                            <>
                                                <h3>Let's find your venue</h3>
                                                <p>Use the search below to find your venue using Google Places.</p>

                                                <LoadScript
                                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} // Using environment variable
                                                    libraries={libraries}
                                                >
                                                    <Autocomplete
                                                        onLoad={onLoad}
                                                        onPlaceChanged={onPlaceChanged}
                                                        options={{
                                                            types: ['establishment'], // Restrict to business establishments
                                                        }}
                                                    >
                                                        <Input
                                                            type="text"
                                                            placeholder="Search for your business"
                                                            className="mb-2"
                                                            style={{ width: '100%', padding: '10px' }}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Autocomplete>
                                                </LoadScript>
                                            </>
                                            
                                        )}
                                        
                                            

                                            {/* Display Error Message */}
                                            {error && (
                                                <Alert color="danger" className="d-flex align-items-center" style={{ fontSize: '16px', padding: '15px', borderRadius: '8px' }}>
                                                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="me-2" /> {/* FontAwesome icon */}
                                                    <span>{error}</span>
                                                </Alert>
                                            )}

                                            

                                            {/* Conditionally render the Request Access button if venueExists is true */}
                                            {venueExists && (
                                                <div className="mb-3">
                                                    <Button color="none" className="btn btn-bwny-primary" onClick={handleAddMyVenue}>
                                                        Get Access to your venue
                                                    </Button>
                                                </div>
                                            )}

                                            {/* Display Selected Venue Details */}
                                            {selectedPlace && availableVenues.length === 0 && success === '' && (
                                                <>
                                                    <h4 className="card-title">Verify and Add</h4>
                                                    <p>Please review the details below and submit</p>
                                                    <p><strong>Name:</strong> {selectedPlace.name}</p>
                                                    <p><strong>Address:</strong> {selectedPlace.address}</p>
                                                    <p><strong>Google Place ID:</strong> {selectedPlace.placeId}</p>
                                                    <Button 
                                                        color="none" 
                                                        className="btn btn-bwny-primary" 
                                                        onClick={handleAddMyVenue}
                                                        disabled={isAddingVenue}
                                                    >
                                                        {isAddingVenue ? 'Adding...' : 'Add My Venue'}
                                                    </Button>
                                                    <Button color="link" onClick={handleManualAdd}>
                                                        My business is not on Google, manually add
                                                    </Button>
                                                </>
                                            )}

                                            {/* Display Table of Available Venues */}
                                            {availableVenues.length > 0 && (
                                                <div className="mt-4">
                                                    <h4>Looks like we found your venue</h4>
                                                    <p>Use the Get Access button to access your venue:</p>
                                                    <Table responsive bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Venue ID</th>
                                                                <th>Venue Name</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {availableVenues.map((venue) => (
                                                                <tr key={venue.v_id}>
                                                                    <td>{venue.v_id}</td>
                                                                    <td>{venue.v_name}</td>
                                                                    <td>
                                                                        <Button 
                                                                            color="none" 
                                                                            className="btn btn-bwny-primary btn-sm"
                                                                            onClick={() => requestAccessToVenue(venue)}
                                                                            disabled={isAddingVenue}
                                                                        >
                                                                            {isAddingVenue ? 'Requesting...' : 'Get Access'}
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            )}

                                            {/* Manual Add Option */}
                                            {!selectedPlace && availableVenues.length === 0 && success === '' && (
                                                <div className="text-center mb-3">
                                                    <Button color="link" onClick={handleManualAdd}>
                                                        My business is not on Google, manually add
                                                    </Button>
                                                </div>
                                            )}

                                            {/* Conditionally render the New Venue form */}
                                            {showAddVenueForm && (
                                                <div className="mt-4">
                                                    <h4>Manually Add Your Venue</h4>
                                                    <Form onSubmit={handleManualFormSubmit}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="b_type_id">Type of Business*</Label>
                                                                    <Input 
                                                                        type="select" 
                                                                        name="b_type_id" // Changed from 'businessType' to 'b_type_id'
                                                                        id="b_type_id" 
                                                                        required
                                                                        value={manualFormData.b_type_id}
                                                                        onChange={handleManualFormChange}
                                                                    >
                                                                        <option value="">Select Type</option>
                                                                        <option value="1">Event Venue</option>
                                                                        <option value="2">Audio Visual Equipment Rental</option>
                                                                        <option value="3">Caterer</option>
                                                                        <option value="4">Party Equipment Rental</option>
                                                                        <option value="5">Videographer</option>
                                                                        <option value="6">Transportation Provider</option>
                                                                        <option value="7">Photographer</option>
                                                                        <option value="8">Live Music</option>
                                                                        <option value="9">DJ</option>
                                                                        <option value="10">Entertainer</option>
                                                                        <option value="11">Event Planner</option>
                                                                        <option value="12">Officiant</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_name">Business Name*</Label>
                                                                    <Input 
                                                                        type="text" 
                                                                        name="v_name" // Changed from 'businessName' to 'v_name'
                                                                        id="v_name" 
                                                                        placeholder="Your Business Name" 
                                                                        required 
                                                                        value={manualFormData.v_name}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_address">Address*</Label>
                                                                    <Input 
                                                                        type="text" 
                                                                        name="v_address" // Changed from 'address' to 'v_address'
                                                                        id="v_address" 
                                                                        placeholder="Address" 
                                                                        required 
                                                                        value={manualFormData.v_address}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_city">City*</Label>
                                                                    <Input 
                                                                        type="text" 
                                                                        name="v_city" // Changed from 'city' to 'v_city'
                                                                        id="v_city" 
                                                                        placeholder="City" 
                                                                        required 
                                                                        value={manualFormData.v_city}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_state">State*</Label>
                                                                    <Input 
                                                                        type="select" 
                                                                        name="v_state" // Changed from 'state' to 'v_state'
                                                                        id="v_state" 
                                                                        required
                                                                        value={manualFormData.v_state}
                                                                        onChange={handleManualFormChange}
                                                                    >
                                                                        <option value="">Select State</option>
                                                                        <option value="NY">New York</option>
                                                                        <option value="AL">Alabama</option>
                                                                        <option value="AK">Alaska</option>
                                                                        <option value="AZ">Arizona</option>
                                                                        <option value="AR">Arkansas</option>
                                                                        <option value="CA">California</option>
                                                                        <option value="CO">Colorado</option>
                                                                        <option value="CT">Connecticut</option>
                                                                        <option value="DE">Delaware</option>
                                                                        <option value="DC">District Of Columbia</option>
                                                                        <option value="FL">Florida</option>
                                                                        <option value="GA">Georgia</option>
                                                                        <option value="HI">Hawaii</option>
                                                                        <option value="ID">Idaho</option>
                                                                        <option value="IL">Illinois</option>
                                                                        <option value="IN">Indiana</option>
                                                                        <option value="IA">Iowa</option>
                                                                        <option value="KS">Kansas</option>
                                                                        <option value="KY">Kentucky</option>
                                                                        <option value="LA">Louisiana</option>
                                                                        <option value="ME">Maine</option>
                                                                        <option value="MD">Maryland</option>
                                                                        <option value="MA">Massachusetts</option>
                                                                        <option value="MI">Michigan</option>
                                                                        <option value="MN">Minnesota</option>
                                                                        <option value="MS">Mississippi</option>
                                                                        <option value="MO">Missouri</option>
                                                                        <option value="MT">Montana</option>
                                                                        <option value="NE">Nebraska</option>
                                                                        <option value="NV">Nevada</option>
                                                                        <option value="NH">New Hampshire</option>
                                                                        <option value="NJ">New Jersey</option>
                                                                        <option value="NM">New Mexico</option>
                                                                        <option value="NC">North Carolina</option>
                                                                        <option value="ND">North Dakota</option>
                                                                        <option value="OH">Ohio</option>
                                                                        <option value="OK">Oklahoma</option>
                                                                        <option value="OR">Oregon</option>
                                                                        <option value="PA">Pennsylvania</option>
                                                                        <option value="RI">Rhode Island</option>
                                                                        <option value="SC">South Carolina</option>
                                                                        <option value="SD">South Dakota</option>
                                                                        <option value="TN">Tennessee</option>
                                                                        <option value="TX">Texas</option>
                                                                        <option value="UT">Utah</option>
                                                                        <option value="VT">Vermont</option>
                                                                        <option value="VA">Virginia</option>
                                                                        <option value="WA">Washington</option>
                                                                        <option value="WV">West Virginia</option>
                                                                        <option value="WI">Wisconsin</option>
                                                                        <option value="WY">Wyoming</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_zip">Zip*</Label>
                                                                    <Input 
                                                                        type="text" 
                                                                        name="v_zip" // Changed from 'zip' to 'v_zip'
                                                                        id="v_zip" 
                                                                        placeholder="Zip Code" 
                                                                        required 
                                                                        value={manualFormData.v_zip}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_website">Website</Label>
                                                                    <Input 
                                                                        type="url" 
                                                                        name="v_website" // Changed from 'website' to 'v_website'
                                                                        id="v_website" 
                                                                        placeholder="Business website" 
                                                                        value={manualFormData.v_website}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="v_phone">Phone Number*</Label>
                                                                    <Input
                                                                        type="tel"
                                                                        name="v_phone" // Changed from 'phoneNumber' to 'v_phone'
                                                                        id="v_phone"
                                                                        placeholder="Business phone number"
                                                                        maxLength="10"
                                                                        pattern="[0-9]{10}"
                                                                        title="Please enter exactly 10 digits"
                                                                        required
                                                                        value={manualFormData.v_phone}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="v_min_budget">Minimum price</Label>
                                                                    <Input 
                                                                        type="number" 
                                                                        name="v_min_budget" // Changed from 'minPrice' to 'v_min_budget'
                                                                        id="v_min_budget" 
                                                                        placeholder="Minimum price for an event" 
                                                                        step="0.01"  // Allows decimals like 199.99
                                                                        min="0"      // Ensures no negative values
                                                                        value={manualFormData.v_min_budget}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <FormGroup>
                                                                    <Label for="v_max_capacity">Max Capacity*</Label>
                                                                    <Input 
                                                                        type="number" 
                                                                        name="v_max_capacity" // Changed from 'maxCapacity' to 'v_max_capacity'
                                                                        id="v_max_capacity" 
                                                                        placeholder="Max number of people for an event" 
                                                                        required 
                                                                        value={manualFormData.v_max_capacity}
                                                                        onChange={handleManualFormChange}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Button 
                                                            type="submit" 
                                                            color="primary" 
                                                            size="sm" 
                                                            disabled={isSubmittingManualForm}
                                                        >
                                                            {isSubmittingManualForm ? 'Submitting...' : 'Submit'}
                                                        </Button>
                                                        <Button 
                                                            type="button" 
                                                            color="secondary" 
                                                            size="sm" 
                                                            className="ml-2" 
                                                            onClick={() => setShowAddVenueForm(false)}
                                                        >
                                                            Close
                                                        </Button>
                                                    </Form>
                                                </div>
                                            )}
                                        </Col>
                                    </>
                                )
                            ) : (
                                <Col className="text-center">
                                    <img 
                                        src="https://bookwny.com/assets/img/logos/BookWNY_Logo_Scroll.png" 
                                        alt="bookWNY Logo" 
                                        className="mb-4" 
                                        style={{ width: '200px' }}
                                    />
                                    <h3>Welcome to your Venue Setup!</h3>
                                    <p className='mb-3'>
                                        We're excited to have you join our platform. To continue with adding a new venue, 
                                        please log in to your account or register. 
                                    </p>
                                    <Link to="/login" className="btn btn-primary">
                                        Log In
                                    </Link>
                                    <Link to="/register" className="btn btn-secondary ml-2">
                                        Register
                                    </Link>
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );

};

export default connect(state => ({
    user: state.user.main,
    roles: state.user.roles,
    activeVenueId: state.user.activeVenueId
}))(NewVenueWorkflow);

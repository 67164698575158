import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelVenue from '../../../../Models/Venue';
import { Alert, Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InviteMembers = ({dispatch, users,venueData, rtl}) => {
    const [modal, setModal] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const mu = new ModelVenue();

    useEffect(async () => {

    },[users]);

    const toggle = async () => {
        setError(false);
        setSuccess(false);
        await setModal(prevState => !prevState);
    };

    const handleSubmit= () =>{
        document.querySelector("#inviteMembersToVenue button[type=submit]").click();
    };

    const continueSubmission=(form)=>{
        form.preventDefault();

        let formData = new FormData(document.getElementById('inviteMembersToVenue'));
        formData.append('venueId',venueData.v_id);
        mu.inviteMember(formData).then(response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setSuccess(false);
                return false;
            }

            setError(false);
            setSuccess(response.data.msg);
        });
    };

    return (
        <div>
            <Button color={"info"} className={"btn-sm btn-block btn-bwny-primary mb-0 mt-2"} onClick={toggle}>Invite Members <FontAwesomeIcon icon="user"/></Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Invite Members</h4>
                </div>

                <div className="modal__body">
                    <form id="inviteMembersToVenue" className={"form--horizontal form"} onSubmit={continueSubmission}>
                        <button type="submit" style={{'display':'none'}}>Submit</button>

                        <Alert
                            color="danger"
                            isOpen={!!errorMsg}
                        >
                            {errorMsg}
                        </Alert>
                        <Alert
                            color="success"
                            isOpen={!!successMsg}
                        >
                            {successMsg}
                        </Alert>

                        <div className="form__form-group mt-3">
                            <div className="col-sm-6">
                                <input type="email" className="form-control form-control-sm" id="email" name="email" required/>
                                <small>Enter the members e-mail address</small>
                            </div>
                            <div className="col-sm-3">
                                <select className="form-control form-control-sm col-12" id="roleId" name="roleId" >
                                    <option value="REGU">Member</option>
                                    <option value="VADM">Venue Admin</option>
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <button className="btn btn-primary btn-sm btn-block" type="button" onClick={handleSubmit}>Invite</button>
                            </div>
                        </div>

                </form>

        </div>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    users:state.users.users,
    venueData:state.venue.venueData
}))(InviteMembers);
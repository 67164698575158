import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import SubmissionsTable from '../../shared/components/Datatables/SubmissionsTable';

const Submissions = ({ dispatch }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    },[]);

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <SubmissionsTable title="Opportunities" limit="100"/>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({

}))(Submissions);
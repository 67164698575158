import { handleActions } from 'redux-actions';
import {
    getAvailableReports,setSelectedReport, setAvailableReports
} from '../actions/reportingActions';

const defaultState = {
    availableReports: [],
    selectedReport: false
};

export default handleActions(
  {
    [getAvailableReports](state, { payload }) {
      return { ...state, availableReports: payload };
    },
    [setAvailableReports](state, { payload }) {
      return { ...state, availableReports: payload };
    },
    [setSelectedReport](state, { payload }) {
      return { ...state, selectedReport: payload };
    }
  },
  defaultState,
);

import React,{ useEffect,useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from "react-router-dom";
import { Card, CardBody,Col, Container, Row } from 'reactstrap';
import {connect} from "react-redux";
import { faUserSlash,faPencilAlt,faEye,faTimes } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';
import {showNotification} from "../Notification";
import ModelSubmission from "../../../Models/Submission";

const SubmissionNotes = ({ dispatch,theme,rtl,submission,notes}) => {

    const history = useHistory();
    const [isLoaded,setLoaded] = useState(false);
    const [categories,setCategories] = useState(false);
    const [addNoteView,setAddNoteView] = useState(false);
    const sm = new ModelSubmission();

    const redirect = (link) =>{
    history.push(link);
    };

    useEffect(() =>{

          setLoaded(true);


    },[submission]);

    const addNote = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('addNoteForm'));
        formData.append('qr_id',submission.qr_id)

        sm.addNote(formData).then(response => {
            if (response.data.status === 'failed') {
                // setError(response.data.msg);
                // setSuccess(false);
                showNotification(theme,'danger',rtl,'Add Note',response.data.msg);

                return false;
            }
            showNotification(theme,'info',rtl,'Add Note',response.data.msg);
            sm.getNotes(submission.qr_id);
            // setError(false);
            // setSuccess(response.data.msg);
            // mu.getUsers().then(response=>{
            //     if (!response) {
            //         alert('failed to load users');
            //         return false;
            //     }
            //     return true;
            // });
        });
    }

    return (
        <>
            {(isLoaded && !addNoteView) &&
                <Col xs={12} className="">
                    <button className="btn-block btn btn-info btn-sm" type={"button"} onClick={() => {
                        setAddNoteView(true)
                    }}>Add Note
                    </button>
                </Col>
            }
            {(!isLoaded) &&
            <Col xs={12} className={"text-center"}>
                <h4>Loading...</h4>
            </Col>
            }

            {(isLoaded && addNoteView) &&
            <form id="addNoteForm" onSubmit={addNote}>
                <Row>
                    <Col xs={12}>
                        <div className="form__form-group ">
                            <label htmlFor="n_note" className="form__form-group-label col-form-label">Note</label>
                            <div className="">
                                <textarea className="form-control form-control-sm" id="n_note" name="n_note" rows="6"/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className={'col-12 text-right'}>
                        <button className="btn btn-danger btn-sm" onClick={()=>setAddNoteView(false)}>Cancel</button>
                        <button className="btn btn-primary btn-sm" type="submit">Save</button>
                    </div>
                </Row>
            </form>
            }

            {(isLoaded && notes === null) &&
            <Col xs={12} className={"text-center"}>
                <h4>No Notes Found</h4>
            </Col>
            }
            {(isLoaded && notes !== null) &&
            <Col xs={12} className={""}>
                {notes.map((note, index) => (
                    <div key={index} className={"note-container pb-2"}>
                        <Row>
                            <Col xs={12}>
                                <p>{note.n_note}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <div>CREATED: <b>{note.n_created_display}</b></div>
                            </Col>
                            <Col sm={6}>
                                <div>BY: <b>{note.u_first}</b></div>
                            </Col>
                        </Row>

                    </div>
                ))}
            </Col>
            }
        </>
    );

};

export default connect(state => ({
  rtl: state.rtl,
  theme: state.theme,
  submission:state.submission.data,
  notes:state.submission.notes
}))(SubmissionNotes);

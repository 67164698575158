import React,{ useState,useEffect } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, Col,Button } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtom } from '@fortawesome/free-solid-svg-icons';
import { useExportData } from 'react-table-plugins';
import Http from '../../App/Http';
import DataReactTable from "./DataReactTable";
import ReportingTable from "./ReportingTable";

const ReportView = ({ dispatch, selectedReport }) => {

    const [inputFields,setInputFields] = useState([]);
    const [data,setData] = useState(null);
    const [reportLoaded,setreportLoaded] = useState(false);
    const [reportLoading,setReportLoading] = useState(false);
    const [reportError,setReportError] = useState(null);
    const history = useHistory();

    // console.log(selectedReport);
    // LOAD THE REPORT INFO AND INPUT FIELDS
    useEffect(()=>{
        // IF THE SELECTED REPORT === FALSE GO TO THE SELECTOR SCREEN
        if(selectedReport === false){
            history.push(`/reporting`);
            return;
        }
        selectedReport.inputs.forEach(function(input){
            let obj = { "key": input.input,"value":""};
            console.log("obj : ", obj);
            setInputFields(inputFields => [...inputFields, obj]);
        });
        console.log("use effect fired");
    },[]);

    // RUN FUNCTION
    const runReport = async () => {
        setreportLoaded(false);
        setReportLoading(true);
        setReportError(null);

        // CHECK THE INPUT AND SET SHOW POP UP IF APPLICABLE
        const formData = new FormData();
        formData.append('reportId',selectedReport.r_id);
        for (let i = 0; i < inputFields.length; i++) {
            formData.append(inputFields[i].key, inputFields[i].value);
        }


        return Http.post("/r/runReport", formData).then(response => {
            if (response.data.status === 'failed') {
                setReportError(response.data.msg);
                return false;
            }

            if(response.data.length === 0){
                // REPORT RETURN NO DATA
                setReportError("THE REPORT RETURNED NO DATA");
                setReportLoading(false);
                return;
            }

            // COLUMNS
            const columns = [];
            Object.keys(response.data[0]).map(function(key) {
                //CREATE THE COLUMNS
                let col = {Header: key,accessor: key};
                columns.push(col);
                // console.log(key,val,response.data[0][key]);
            });

            // DATA
            const tableStructure = {
                tableHeaderData: columns,
                tableRowsData: response.data
            };
            setData(tableStructure);
            setreportLoaded(true);
            setReportLoading(false);
            return;
        }).catch((err)=>{
            console.log(err);
            setReportLoading(false);
            return;
        });
    };

    return (
        <Col md={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">Report - {selectedReport.r_name}</h5>
                        <h5 className="subhead">Reporting Suite</h5>
                    </div>
                    <div id={"reportActions"} className={"row"}>
                        <div className={"col-12"}>
                            <Button color={"primary"} onClick={runReport}>Run <FontAwesomeIcon icon="play"/></Button>
                        </div>
                    </div>
                    <hr style={{background:'#ffffff'}}/>
                    <div className="row">
                        {reportLoading &&
                        <div className={"col-12 text-center"}>
                            <h2 ><FontAwesomeIcon className={"fa-2x text-warning"} icon={faAtom} spin/><br/>Loading ... </h2>
                        </div>
                        }
                        {reportError !== null &&
                            <div className={"col-12"}>
                                <div className="alert alert-dark" role="alert">
                                    { reportError }
                                </div>
                            </div>
                        }
                        <div className="col-12" >
                            {reportLoaded ? <ReportingTable classes={"table--70vh"} reactTableData={ data } /> : ""}
                            {/*{reportLoaded ? <DataReactTable classes={"table--70vh"} reactTableData={ data } /> : ""}*/}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );

};

export default connect(state => ({
    selectedReport:state.reporting.selectedReport
}))(ReportView);

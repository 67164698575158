import React,{ useEffect,useState } from 'react';
import Panel from "../../Panel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../../Models/Submissions';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../DataTable";

const SubscriptionsTable = ({ dispatch, venues,venueData, venueSubscriptions, title, limit, activeVenueId}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const um = new ModelSubmissions();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof venueSubscriptions !== 'undefined' || venueSubscriptions!==null){
            setLoaded(true);
        }
    },[venueSubscriptions,venueData]);

    const columns =[
        // {
        //     width: 300,
        //     Header: "Options",
        //     Cell: ({ cell }) => {
        //         // debugger
        //         return (
        //             <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-danger btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={e => {e.preventDefault(); removeMemberAccess(cell.row.original)}}>
        //                 Remove <FontAwesomeIcon icon={faUserSlash}/>
        //             </a>
        //         )
        //     }
        // },
        {
            Header: 'Subscription Id',
            accessor: 's_stripe_id'
        },{
            Header: 'Email',
            accessor: 'u_email',
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (


                <DataTable columns={columns} endpoint={"v/"+venueData.v_id+"/getSubscriptions"}/>


    );
};

export default connect(state => ({
    venues:state.venues.venues,
    venueData:state.venue.venueData,
    venueSubscriptions:state.venue.subscriptions,
    activeVenueId : state.user.activeVenueId
}))(SubscriptionsTable);

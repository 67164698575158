import React, { useState, useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table';
import {
    Table,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from 'reactstrap';
import Http from "../../../../containers/App/Http"; // Adjust the import path as necessary

// 1. Define Filter Components

// a. Default (Standard Search) Filter
const DefaultColumnFilter = ({
    column: { filterValue, setFilter },
}) => (
    <Input
        value={filterValue || ''}
        onChange={e => setFilter(e.target.value || undefined)}
        placeholder="Search..."
        className="form-control form-control-sm"
    />
);

// b. Number Range Filter
const NumberRangeColumnFilter = ({
    column: { filterValue = [], setFilter },
}) => (
    <div style={{ display: 'flex' }}>
        <Input
            value={filterValue[0] || ''}
            type="number"
            onChange={e => {
                const val = e.target.value;
                setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
            }}
            placeholder="Min"
            style={{ width: '70px', marginRight: '0.5rem' }}
            className="form-control form-control-sm"
        />
        <Input
            value={filterValue[1] || ''}
            type="number"
            onChange={e => {
                const val = e.target.value;
                setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
            }}
            placeholder="Max"
            style={{ width: '70px' }}
            className="form-control form-control-sm"
        />
    </div>
);

// c. Date Range Filter (Using Standard Date Inputs)
const DateRangeColumnFilter = ({
    column: { filterValue = [], setFilter },
}) => {
    const [startDate, endDate] = filterValue;

    const handleStartDateChange = (e) => {
        const date = e.target.value ? new Date(e.target.value) : undefined;
        setFilter((old = []) => [date, old[1]]);
    };

    const handleEndDateChange = (e) => {
        const date = e.target.value ? new Date(e.target.value) : undefined;
        setFilter((old = []) => [old[0], date]);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Input
                type="date"
                value={startDate ? startDate.toISOString().split('T')[0] : ''}
                onChange={handleStartDateChange}
                placeholder="Start Date"
                className="form-control form-control-sm"
            />
            <Input
                type="date"
                value={endDate ? endDate.toISOString().split('T')[0] : ''}
                onChange={handleEndDateChange}
                placeholder="End Date"
                className="form-control form-control-sm"
            />
        </div>
    );
    
};

// 2. Assign Filters Dynamically Based on Column's 'filter' Property
const assignFilter = (column) => {
    if (column.filter && column.accessor) {
        switch (column.filter) {
            case "numberRange":
                return NumberRangeColumnFilter;
            case "dateRange":
                return DateRangeColumnFilter;
            case "default":
            default:
                return DefaultColumnFilter;
        }
    }
    return undefined; // No filter for this column
};

// 3. Column Selector Component
const ColumnSelector = ({ columns, visibleColumns, toggleColumn }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
    const handleCheckboxClick = (e) => e.stopPropagation();

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret className={"btn-sm btn-bwny-primary"} color="">
                Columns
            </DropdownToggle>
            <DropdownMenu>
                {columns
                    .filter(column => column.accessor) // Only include columns with an accessor
                    .map(column => (
                        <DropdownItem key={column.accessor} toggle={false}>
                            <label className="m-0" onClick={handleCheckboxClick}>
                                <Input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={!!visibleColumns[column.accessor]}
                                    onChange={() => toggleColumn(column.accessor)}
                                    style={{ marginRight: '0.5rem' }}
                                />
                                {column.Header}
                            </label>
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </Dropdown>
    );
};

// 4. Main DataTable Component
const DataTable = ({ endpoint, columns }) => {
    // State Management
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [search, setSearch] = useState("");
    const [visibleColumns, setVisibleColumns] = useState(() => {
        const visibility = {};
        columns.forEach(column => {
            if (column.accessor) {
                visibility[column.accessor] = true;
            }
        });
        return visibility;
    });

    // 4.1. Debounce Search Input
    const [debouncedSearch, setDebouncedSearch] = useState(search);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500); // 500ms debounce

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    // 4.2. Memoize Filtered Columns
    const filteredColumns = useMemo(
        () => columns.filter(column => !column.accessor || visibleColumns[column.accessor]),
        [columns, visibleColumns]
    );

    // 4.3. Memoize Table Columns with Assigned Filters
    const tableColumns = useMemo(
        () =>
            filteredColumns.map(column => ({
                ...column,
                Filter: assignFilter(column),
            })),
        [filteredColumns]
    );

    // 4.4. Define Default Column (No Default Filter)
    const defaultColumn = useMemo(
        () => ({
            // Do not set Filter here to avoid all columns having a default filter
        }),
        []
    );

    // 4.5. Fetch Data Function
    const fetchData = async ({ pageSize, pageIndex, sortBy, filters, searchTerm }) => {
        setLoading(true);
        const sortByData = sortBy[0] || {};
        const formData = new FormData();

        // Append basic fields
        formData.append('limit', pageSize);
        formData.append('page', pageIndex + 1);
        formData.append('sort', sortByData.id || "");
        formData.append('order', sortByData.desc ? 'desc' : 'asc');
        formData.append('term', searchTerm || "");

        // Initialize filters array
        const filtersArray = [];

        // Iterate over each filter and structure them accordingly
        filters.forEach(filter => {
            const { id, value } = filter;
            const column = columns.find(col => col.accessor === id);

            if (column && column.filter) {
                const filterType = column.filter;

                switch (filterType) {
                    case 'numberRange':
                        filtersArray.push({
                            id: id,
                            type: 'numberRange',
                            min: value[0] !== undefined ? value[0] : null,
                            max: value[1] !== undefined ? value[1] : null
                        });
                        break;
                    case 'dateRange':
                        filtersArray.push({
                            id: id,
                            type: 'dateRange',
                            startDate: value[0] ? value[0].toISOString().split('T')[0] : null, // Format as YYYY-MM-DD
                            endDate: value[1] ? value[1].toISOString().split('T')[0] : null
                        });
                        break;
                    case 'default':
                    default:
                        filtersArray.push({
                            id: id,
                            type: 'default',
                            value: value !== undefined ? value : null
                        });
                        break;
                }
            }
        });

        // Append filters array as JSON string
        if (filtersArray.length > 0) {
            formData.append('filters', JSON.stringify(filtersArray));
        }

        try {
            const response = await Http.post(endpoint, formData, {
                headers: {
                    // Let the browser set the Content-Type, including boundaries
                    // 'Content-Type': 'multipart/form-data' // Do not set manually
                }
            });

            setData(response.data.data);
            const totalCount = parseInt(response.headers['x-total-count'], 10);
            setPageCount(Math.ceil(totalCount / pageSize));
        } catch (error) {
            console.error("Error fetching data:", error);
            // Optionally handle error state here
        } finally {
            setLoading(false);
        }
    };

    // 4.6. Initialize React Table Hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Use 'page' instead of 'rows' when using pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable(
        {
            columns: tableColumns,
            data,
            defaultColumn, // Pass the defaultColumn without Filter
            manualPagination: true,
            pageCount,
            manualSortBy: true,
            manualFilters: true, // Enable manual filters
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useSortBy,
        usePagination
    );

    // 4.7. Fetch Data Whenever Relevant State Changes
    useEffect(() => {
        fetchData({
            pageIndex,
            pageSize,
            sortBy,
            filters,
            searchTerm: debouncedSearch,
        });
    }, [pageIndex, pageSize, sortBy, filters, debouncedSearch]); // Note: use debouncedSearch

    // 4.8. Handle Column Visibility Changes
    const toggleColumn = (accessor) => {
        setVisibleColumns(prev => ({
            ...prev,
            [accessor]: !prev[accessor],
        }));
    };

    // 5. Render the Component
    return (
        <>
            {/* 5.1. Control Panel */}
            <Row className="mb-3">
                {/* Search Input */}
                <Col xs={12} sm={4} className="pl-1 pr-1 mb-2 mb-sm-0">
                    <Input
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search..."
                        className="form-control form-control-sm"
                    />
                </Col>

                {/* Page Size Selector */}
                <Col xs={6} sm={4} className="pl-1 pr-1 mb-2 mb-sm-0">
                    <Input
                        type="select"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value));
                        }}
                        className="form-control form-control-sm"
                    >
                        <option value={10}>Show 10</option>
                        <option value={20}>Show 20</option>
                        <option value={40}>Show 40</option>
                        <option value={50}>Show 50</option>
                    </Input>
                </Col>

                {/* Column Selector Dropdown */}
                <Col xs={6} sm={4} className="text-right pl-1 pr-1">
                    <ColumnSelector
                        columns={columns}
                        visibleColumns={visibleColumns}
                        toggleColumn={toggleColumn}
                    />
                </Col>

                {/* 5.2. Render Filters */}
                {headerGroups.map(headerGroup => (
                    <React.Fragment key={headerGroup.id}>
                        {headerGroup.headers.map(column => (
                            <React.Fragment key={column.id}>
                                {/* Check if column has a filter */}
                                {column.canFilter && column.Filter ? (
                                    <div className="pr-2 mb-2">
                                        <strong>{column.render('Header')}:</strong> {/* Filter name */}
                                        {column.render('Filter')} {/* Filter input */}
                                    </div>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </Row>

            {/* 5.3. Data Table */}
            <Row>
                <Col xs={12} className="table-responsive">
                    <Table {...getTableProps()} className="react-table resizable-table table-striped table-bordered" style={{ minHeight: '600px' }}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id}>
                                            <div className="d-flex align-items-center">
                                                {column.render('Header')}
                                                <span style={{ marginLeft: '0.5rem' }}>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {loading ? (
                                <tr>
                                    <td colSpan={filteredColumns.length} className="text-center">
                                        <Spinner size="sm" color="primary" /> Loading...
                                    </td>
                                </tr>
                            ) : page.length > 0 ? (
                                page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={filteredColumns.length} className="text-center">
                                        No data found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {/* 5.4. Pagination Controls */}
            <Row className="mt-3">
                <Col xs={12}>
                    <Pagination aria-label="Page navigation example" className="justify-content-center">
                        <PaginationItem disabled={!canPreviousPage}>
                            <PaginationLink
                                previous
                                onClick={() => previousPage()}
                                href="#"
                            />
                        </PaginationItem>
                        {/* Display page numbers */}
                        {pageOptions.map((pageOption, index) => (
                            <PaginationItem key={index} active={pageIndex === pageOption}>
                                <PaginationLink onClick={() => gotoPage(pageOption)} href="#">
                                    {pageOption + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={!canNextPage}>
                            <PaginationLink
                                next
                                onClick={() => nextPage()}
                                href="#"
                            />
                        </PaginationItem>
                    </Pagination>
                </Col>
            </Row>
        </>
    );
}

export default DataTable;

import React, { useState } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import ModalChangeTier from "./ModalChangeTier";
import UpgradePlan from "../../../../shared/components/UpgradePlan";
import {connect} from "react-redux";


const VenueTier = ({dispatch, venueData }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget p-3 d-flex flex-column" >
          <div className="card__title">
            <h5 className="bold-text">Venue Tier</h5>
          </div>
          <div className="card__body flex-grow-1">
            <h3>{ venueData.vti_name }</h3>
          </div>
          <div className="mt-auto">
            <UpgradePlan currentTier={venueData}/>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData,
    venueMembers:state.venue.members
}))(VenueTier);
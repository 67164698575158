import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import { faChartLine,faUser ,faHotel,faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelUser from "../../../Models/User";
import {showNotification} from "../../../shared/components/Notification";
import { Link,useParams,useHistory } from 'react-router-dom';


const AcceptVenueInvitation = ({ dispatch,venueData, roles,user,theme,rtl }) => {
    const { token } = useParams();
    const { id } = useParams();
    const [loaded,setLoaded]=useState(false);
    const um = new ModelUser();
    const history = useHistory();


    useEffect(()=>{
        // CHECK TO MAKE SURE WE HAVE THE TRANSACTION ID AND TOKEN
        if(typeof id === "undefined" || typeof token ==="undefined"){
            history.push("/profile");
            showNotification(theme,'danger',rtl,'Access','Missing parameters');
        }

        // SEND THE REQUEST TO ACCEPT
        let formData = new FormData();
        formData.append('id',id);
        formData.append('token',token);
        um.acceptVenueInvitation(formData).then((response)=>{
            // IF FAILED THEN REROUTE TO PROFILE PAGE
            if(response.data.status === 'failed'){
                history.push("/profile");
                showNotification(theme,'danger',rtl,'Invitation',response.data.msg);
            }

            // ON SUCCESS GO TO VENUE PAGE AND RELOAD THE USER OBJECT
            history.push("/profile");
        });

    },[]);

    return null
}

export default connect(state => ({
    venueData:state.venue.venueData,
    roles : state.user.roles,
    user : state.user.main,
    rtl: state.rtl,
    theme: state.theme
}))(AcceptVenueInvitation);
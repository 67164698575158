import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ModelVenue from '../../../../Models/Venue';
import {Button, ButtonToolbar, Modal, Form, FormGroup, Label, Input, FormText, Row, Col} from 'reactstrap';
import { faTimesCircle, faCheckCircle, faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid';

const ModalChangeStatus = ({ dispatch, venueData, rtl }) => {
    const [modal, setModal] = useState(false);
    const [status, setStatus] = useState(null);
    const [confirmMessage, setConfirmMessage] = useState('');
    const mv = new ModelVenue();

    useEffect(() => {
        setStatus(venueData.v_active);
    }, [venueData]);

    const toggle = async () => {
        await setModal((prevState) => !prevState);
    };

    const handleSubmit = () => {
        let newStatus = status === 0 ? 1 : 0;
        mv.changeVenueStatus(venueData.v_id, newStatus).then((response) => {
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            } else {
                toggle(); // Close the modal
                mv.getVenueData(venueData.v_id);
            }
        });
    };

    const handleConfirm = () => {
        if (status === 0) {
            setConfirmMessage('Are you sure you want to activate this venue?');
        } else {
            setConfirmMessage('Are you sure you want to deactivate this venue?');
        }
    };

    return (
      <div>
          <Button
            color={status === 0 ? 'primary' : 'danger'}
            className={"btn-sm btn-block mb-0 mt-2"}
            onClick={toggle}
          >
              {status === 0 ? (
                <span>
                  Activate
                </span>
                          ) : (
                            <span>
                              Deactivate
                            </span>
                          )}
          </Button>

          <Modal
            isOpen={modal}
            toggle={toggle}
            className={`modal-dialog--primary modal-dialog--header`}
            modalClassName={`${rtl.direction}-support`}
            size="sm"
          >
              <div className="modal__header">
                  <button
                    className="lnr lnr-cross modal__close-btn"
                    aria-label="modal__close-btn"
                    type="button"
                    onClick={toggle}
                  />
                  <h4 className="text-modal  modal__title">Change Status</h4>
              </div>
              <div className="modal__body">
                  <p className="modal__message">
                      {status === 0
                        ? 'Are you sure you want to activate this venue?'
                        : 'Are you sure you want to deactivate this venue?'}
                  </p>
                  <Row>
                      <Col xs={6} className="text-center">
                          <Button color="danger" className="modal_cancel btn-sm btn-block" onClick={toggle}>Cancel</Button>{' '}
                      </Col>
                      <Col xs={6} className="text-center">
                          <Button color="primary" className="modal_ok btn-sm btn-block " onClick={handleSubmit}>Confirm</Button>
                      </Col>

                  </Row>
              </div>
          </Modal>
      </div>
    );
};

export default connect((state) => ({
    rtl: state.rtl,
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting: state.venue.dateRangeStarting,
    venueData: state.venue.venueData,
}))(ModalChangeStatus);
import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setAllSubmissions} from "../redux/actions/submissionsActions";
import {setSubmission, setSubmissionConversation, setSubmissionNotes} from "../redux/actions/submissionActions";

export default class Submission{

    getFullSubmission(s_id){
        const activeVenueId = store.getState().user.activeVenueId;

        const formData = new FormData();
        formData.append('s_id',s_id);
        // formData.append('queryPageSize',queryPageSize);

        return Http.post("/v/"+activeVenueId+"/s/"+s_id+"/getFullSubmission",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setSubmission(response.data.data[0][0]));
            await store.dispatch(setSubmissionConversation(response.data.data[1]));
            await store.dispatch(setSubmissionNotes(response.data.data[2]));

            return response;
        });
    };

    getSubmission(s_id){
        // FETCH THE Users
        const formData = new FormData();
        formData.append('s_id',s_id);
        // formData.append('queryPageSize',queryPageSize);

        return Http.post("/sub/getSubmission",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setSubmission(response.data.data[0]));
            await store.dispatch(setSubmissionConversation(response.data.data[1]));
            await store.dispatch(setSubmissionNotes(response.data.data[2]));


            return response;
        });
    };

    getNotes(s_id){
        // FETCH THE Users
        const formData = new FormData();
        formData.append('s_id',s_id);
        // formData.append('queryPageSize',queryPageSize);

        return Http.post("/sub/getNotes",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setSubmissionNotes(response.data.data));
            return response;
        });
    };

    addNote(formData){

        return Http.post("/sub/addNote",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

}
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TopbarMenuLinks = ({ title, icon, path }) => (
  <Link className="topbar__link" to={path}>
    <p className="topbar__link-title">{icon ? <FontAwesomeIcon icon={icon} /> : ''} {title}</p>
  </Link>
);

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default TopbarMenuLinks;

import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const StripeContainer = ({ component: Component }) => {

    const PUBLIC_KEY = (process.env.NODE_ENV ==='development') ? "pk_test_51J5rZGDhV7PrKb79xF2y1pfXWjwd4WTmLy1iJmAmSYFoCUxk1WsliNlGHHbMFE3lHxbNsJDovqXGro9s6pbuysuD00mpqYrSIc":"pk_live_51J5rZGDhV7PrKb79jXZoh1fxVU88qaWqHcpn8j04GZzCveeRMLgonQp48RRSFGYADOaJt5sV8Germ2aK3XduHKQf00A0u9qmg9" ;

    const stripeTestPromise = loadStripe(PUBLIC_KEY);

    return (
        <Elements stripe={stripeTestPromise}>

            <Component />

        </Elements>
    )

}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    users:state.users.users,
    venueData:state.venue.venueData
}))(StripeContainer);
import React, { useState,useEffect } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import SidebarCategory from "../sidebar/SidebarCategory";
import Sidebar from "../sidebar/Sidebar";
import { faChartLine,faUser ,faSignOutAlt,faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({changeToDark,changeToLight}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const {u_first,u_last} = JSON.parse(localStorage.getItem("user"));
    const [isLoaded,setLoaded] = useState(false);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    let AccountIcon = u_first.trim().charAt(0).toUpperCase()+u_last.trim().charAt(0).toUpperCase() ;

    useEffect(()=>{
        setLoaded(true);
    },[]);

    return (
        <div className="topbar__profile">
          <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
            {/*<img className="topbar__avatar-img" src={Ava} alt="avatar" />*/}
              <div style={{padding: ".5rem",height: '60px', verticalAlign: 'middle'}}><p style={{padding:'.5rem',borderRadius:'30px',color:'#222222',background:'#888888',fontSize:'1.3rem'}}>{AccountIcon}</p></div>

            <p className="topbar__avatar-name">{u_first} {u_last}</p>
            <DownIcon className="topbar__icon" />
          </button>
          {isCollapsed && (
            <button
              type="button"
              aria-label="button collapse"
              className="topbar__back"
              onClick={handleToggleCollapse}
            />
          )}
          <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
            <div className="topbar__menu">
              <TopbarMenuLink title="My Profile" icon={faUser} path="/profile" />
              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Log Out" icon={faSignOutAlt} path="/logout" />
                <button type="button" className="sidebar__link" onClick={changeToLight}>
                    <p className="sidebar__link-title">Light Theme</p>
                </button>
                <button type="button" className="sidebar__link" onClick={changeToDark}>
                    <p className="sidebar__link-title">Dark Theme</p>
                </button>
            </div>
          </Collapse>
        </div>
    );
};

export default TopbarProfile;

import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelSubmissions from '../../../../Models/Submissions';
import {Alert, Button, ButtonToolbar, Modal, Form, FormGroup, Label, Input, FormText, Col, Row} from 'reactstrap';

const SubmissionDetails = ({dispatch, rtl,submission,conversation}) => {
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);

    useEffect(() => {

    },[submission]);

    return (
        <>
            <Row className="border-bottom pt-3 pb-3 mb-3">
                <Col xs={12}>
                    <h3>Details</h3>
                </Col>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Request ID</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.qr_id} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Created</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.qr_created_date} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Venue</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.v_name} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label"># of People</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.qr_num_of_people} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Event Budget</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.qr_event_budget} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">E-Mail</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.qr_email} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Event Type</label>
                    <div className=" col-sm-12">
                        <input name="" type="text" value={submission.et_name} placeholder="" className="form-control-sm form-control"/>
                    </div>
                </div>
            </Row>
            <Row>
                <div className="form__form-group mb-1">
                    <label htmlFor="" className="pb-0 col-sm-12 form__form-group-label col-form-label">Message</label>
                    <div className=" col-sm-12">
                        <textarea className="form-control">{submission.qr_message}</textarea>
                    </div>
                </div>
            </Row>
        </>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    users:state.users.users,
    submission:state.submission.data
}))(SubmissionDetails);
import { createAction } from 'redux-actions';

export const setDateRangeStarting = createAction('SET_DATE_RANGE_STARTING');
export const setDateRangeEnding = createAction('SET_DATE_RANGE_ENDING');
export const getVenueData = createAction('GET_VENUE_DATA');
export const setVenueData = createAction('SET_VENUE_DATA');
export const setVenueImages = createAction('SET_VENUE_IMAGES');
export const setVenueStats = createAction('SET_VENUE_STATS');
export const showVenueMenu = createAction('SHOW_VENUE_MENU');
export const setVenueMembers = createAction('SET_VENUE_MEMBERS');
export const setVenueSubscriptions = createAction('SET_VENUE_SUBSCRIPTIONS');
import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import modelVenue from '../../../../Models/Venue';
import { Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import Http from "../../../App/Http";
import {showNotification} from "../../../../shared/components/Notification";

const ModalUploadImage = ({dispatch, venueData, rtl,theme}) => {
    const [modal, setModal] = useState(false);
    const [status,setStatus] = useState(null);
    const mv = new modelVenue();

    useEffect(async () => {
        setStatus(venueData.v_active);
    },[venueData]);

    const toggle = async () => {
        await setModal(prevState => !prevState);
    };

    const handleSubmit= (form) =>{
        form.preventDefault();
        let formData= new FormData(document.getElementById("venueImageUploadForm"));
        formData.append('venueId',venueData.v_id);

        // eventsite or enventsite dev1 or site2 local
        Http.post(process.env.REACT_APP_BOOKWNY_INSTANCE_API_URL +"/api/v1/venueUploadImages?apiKey=TDAu9cgB062h4wNXMdikDF1nGIq3XhXq",formData).then(async response => {
            await setModal(prevState => !prevState);
            if (response.data.status === 'failed') {
                showNotification(theme,'danger',rtl,'Add Images',response.data.msg);
                return false;
            }

            mv.getVenueImages(venueData.v_id).then(response => {
                if (response.data.status === "failed") {
                    showNotification(theme,'danger',rtl,'Get Images',response.data.msg);
                    return false;
                }
                return true;
            });

            showNotification(theme,'primary',rtl,'Add Images',response.data.msg);
            return;
        });

    };

    return (
        <div>

            <Button color={"none"} className="btn-bwny-primary btn-sm btn-block mb-0 mt-2" onClick={toggle}>Upload Images</Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="sm">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Select File</h4>
                </div>
                <div className="modal__body">
                    <Form id={'venueImageUploadForm'} onSubmit={handleSubmit}>

                        <Input
                            type='file' label='Upload' accept='.jpg,.jpeg,.png' name={'files'}
                            // buttonAfter={uploadFileButton}
                            // ref={(ref) => this.fileUpload = ref}
                        />

                        <ButtonToolbar className="modal__footer">
                            <Button className="modal_ok" color={'primary'} type={"submit"}>Submit</Button>{' '}
                            <Button className="modal_cancel" color={'danger'} onClick={toggle}>Cancel</Button>
                        </ButtonToolbar>
                    </Form>
                </div>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData
}))(ModalUploadImage);
import { handleActions } from 'redux-actions';
import {
    setSubmission, setSubmissionConversation, setSubmissionNotes
} from '../actions/submissionActions';

const defaultState = {
    data:null,
    conversation:null,
    notes:null,
};

export default handleActions(
  {
    [setSubmission](state, { payload }) {
        return {...state, data: payload};
    },[setSubmissionConversation](state, { payload }) {
        return {...state, conversation: payload};
    },[setSubmissionNotes](state, { payload }) {
        return {...state, notes: payload};
    }
  },
  defaultState,
);

import React,{ useEffect,useState } from 'react';
import SubscriptionsTable from '../../../../shared/components/Datatables/SubscriptionsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelVenue from '../../../../Models/Venue';
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../shared/components/Notification";
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CreateSubscription from "./CreateSubscription";
import UpgradePlan from "../../../../shared/components/UpgradePlan";


const SubscriptionsComponent = ({ dispatch, venueSubscriptions ,venueData,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const vm = new ModelVenue();

    const fetchData = async () => {
        vm.getSubscriptions(venueData.v_id).then(response=>{
            if (!response) {
                alert('failed to subscriptions');
                return false;
            }
            return true;
        });
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof venueSubscriptions !== 'undefined' || venueSubscriptions!==null){
            setLoaded(true);
        }
    },[venueSubscriptions]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    return (
        <>
            <Col xs={'12'}>
                <UpgradePlan/>
            </Col>
            <Col xs={'12'} className={'mt-4'}>
                {isTableLoaded &&
                <>
                    <SubscriptionsTable/>
                </>
                }
            </Col>
        </>
    );
};

export default connect(state => ({
    venueData:state.venue.venueData,
    venueMembers:state.venue.members,
    venueSubscriptions:state.venue.subscriptions,
    rtl: state.rtl,
    theme: state.theme
}))(SubscriptionsComponent);

import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import ModelVenue from '../../../../Models/Venue';
import {showNotification} from "../../../../shared/components/Notification";

const Amenities = ({dispatch,venueData,theme,rtl}) => {

    const vm = new ModelVenue();


    useEffect(() =>{

        vm.getAvailableAmenities(venueData.v_id).then(response => {
            if(response.data.status === 'failed'){
                showNotification(theme,'danger',rtl,'Get Amenities',response.data.msg);
                return;
            }

            let amenitiesContainer = document.getElementById("amenitiesContainer");

            amenitiesContainer.innerHTML = "";
            response.data.data.amenities.forEach(function(item){

                let el= document.createElement('div');
                el.setAttribute('class',"form-check");
                el.innerHTML = `<input type="checkbox" class="form-check-input" data-venue-amenity-id="`+item.va_id+`" name="amenities[]" value="`+item.va_id+`" id="amenities`+item.va_id+`">
                    <label class="form-check-label checkbox-btn__label" for="amenities`+item.va_id+`">`+item.va_name+`</label>`;
                amenitiesContainer.insertBefore(el, amenitiesContainer.firstChild);
            });

            if(!venueData.hasOwnProperty('amenities'))
                return;

            venueData.amenities.forEach((am)=>{
                console.log(am);
                // document.querySelector("[data-venue-amenity-id="+am.va_id+"]").checked = true;
                document.querySelector("[data-venue-amenity-id='"+am.va_id+"']").checked = true;
            });

        });
    },[venueData]);

    const updateVenueAmenities = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('updateVenueAmenitiesForm'));
        // venue type ids
        // let vt_ids = "";
        // let comma = "";
        // if(document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').length > 0){
        //     document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').forEach(function(x){
        //         vt_ids += comma + x.value;
        //         comma = ",";
        //     });
        // }
        // formData.append('vt_ids',vt_ids);

        let v_outdoor_space_available = "N";
        if(document.querySelectorAll('input[type=checkbox][name=v_outdoor_space_available]:checked').length > 0){
            v_outdoor_space_available = 'Y';
        }
        formData.append('v_outdoor_space_available',v_outdoor_space_available);

        vm.updateVenueAmenities(venueData.v_id,formData).then((response)=>{
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Get Images',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update Venue Amenities',response.data.msg);
            return;
        });

    };

    return (
        <>
            <form id="updateVenueAmenitiesForm" onSubmit={updateVenueAmenities}>
                <div id={'amenitiesContainer'}></div>


                <button type={'submit'} className="btn btn-primary">Update</button>
            </form>
        </>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(Amenities);